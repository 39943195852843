<template>
    <div id="content" class="issues" :class="{ detail: detail, portrait: $mq.includes('portrait') }">
        <!-- MAIN -->
        <div v-if="!detail" class="column left hide-scrollbar">
            <p class="title">{{ $t('issues.filter_title') }}</p>

            <!-- <v-select class="select-date" v-model="date" :items="dateSelector.options" flat hide-details @change="updateFilters()">
                <template v-slot:selection="{ item, index }">
                    <v-list-tile-content>
                        <v-list-tile-title v-html="item.text"></v-list-tile-title>
                    </v-list-tile-content>
                </template>

                <template v-slot:item="{ item, index }">
                    <v-list-tile-content v-if="item.action" @click="updateFilters()">
                        <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                    </v-list-tile-content>

                    <v-list-tile-content v-else>
                        <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                    </v-list-tile-content>
                </template>
            </v-select> -->

            <div class="issues-container">
                <select class="select-date" v-model="date" @change="updateFilters()">
                    <option v-for="item in dateSelector.options" :key="item.text" :value="item.value" :selected="date == item.value">{{ item.text }}</option>
                </select>
                <button v-for="state in issueStates" class="btn" :class="{ selected: showByState.includes(state.id) }" @click="seeState(state.id)">{{ state.name }}</button>
            </div>
        </div>
        <div v-if="!detail" class="content right scrollbar">
            <div class="contain">
                <p v-if="showByState.length > 0" class="total-items">{{ totalItems ? totalItems + ' ' + $tc('issues.name', totalItems) : $t('') }}</p>
                <p v-else class="total-items">{{ $t('issues.all_results') }} ({{ totalItems }})</p>

                <template v-if="Object.keys(issues).length > 0">
                    <item v-for="issue in issues" v-if="issue.is_visible" :item="issue" :cType="type" @sendId="openDetail"></item>
                </template>

                <template v-else>
                    <EmptyPage :title="$t('empty_pages.issues_title')" type="issues" :buttonText="$t('empty_pages.create_issue')" @buttonAction="createIssue" :description="$t('empty_pages.issues_description')"></EmptyPage>
                </template>
            </div>
        </div>

        <!-- DETAIL -->
        <detail v-if="detail" :gId="detail" :cType="type"></detail>
    </div>
</template>

<script>
import EmptyPage from '../components/layout/EmptyPage.vue'
import item from '@/components/domain/issues/item'
import detail from '@/components/domain/issues/detail'

export default {
    name: 'Issues',
    components: { item, detail, EmptyPage },
    created() {
        this.$bar.reset()
        this.load()
    },
    data() {
        return {
            type: 4,
            route_name: 'IssuesDetail',
            route: '/issues',
            detail: this.$route.params.id,
            date: [
                moment()
                    .subtract(30, 'days')
                    .format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
                'month'
            ],
            name: this.$route.params.name,
            showByState: []
        }
    },
    computed: {
        def() {
            return this.$store.getters['issues/getDefaults']
        },
        dateSelector() {
            return this.$store.getters['getDateSelector']
        },
        issues() {
            return this.$store.getters['issues/getAll']
        },

        issueStates() {
            // console.log(this.$store.getters['getIssueStates']);
            return this.$store.getters['getIssueStates']
        },
        userLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            return roleUserLogged
        },

        totalItems() {
            let self = this
            let count = 0

            Object.keys(self.issues).map(function(item_id, i) {
                var item = self.issues[item_id]
                if (item.is_visible) {
                    count++
                }
            })
            return count
        }
    },
    watch: {
        $route: 'load'
    },
    methods: {
        createIssue() {
            var self = this
            if (!self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.$popup.login({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response) {
                            self.$router.push({ name: self.route_name })
                            self.$popup.close()
                        }
                    }
                })
            } else {
                var data = self.$store.getters['issues/getTemplates']

                self.$popup.select({
                    list: data,
                    cType: self.type,
                    title: self.$t('popup.add_template.title'),
                    listEmpty: self.$t('popup.add_template.list_empty'),
                    textSave: self.$t('popup.add_template.text_save'),
                    textSaveStart: self.$t('popup.add_template.text_save_start'),
                    callSave: function(pSelf, store) {
                        if (typeof pSelf.selectedEl !== 'undefined') {
                            return store
                                .dispatch('issues/add', {
                                    tpl_id: pSelf.selectedEl,
                                    cType: self.type
                                })
                                .then(function(response) {
                                    if (response) {
                                        self.setAskTitle = true
                                        pSelf.selectedEl = response.id
                                    } else {
                                        self.$popup.close()
                                    }

                                    return Promise.resolve(pSelf, store)
                                })
                        } else {
                            return Promise.resolve(pSelf, store)
                        }
                    },
                    callSaveStart: function(pSelf, store) {
                        self.$router.push({
                            name: self.route_name,
                            params: { id: pSelf.selectedEl }
                        })
                        return Promise.resolve(pSelf, store)
                    }
                })
            }
        },
        seeState(state) {
            let self = this

            if (this.showByState.includes(state)) {
                this.showByState.splice(this.showByState.indexOf(state), 1)
            } else {
                this.showByState.push(state)
            }

            self.$store.commit('issues/filterByState', { states: self.showByState })

            self.detail = false
            self.$bar.show()
            self.$bar.reset()

            self.barActionsMain()

            self.$overlay.hide()
        },

        openDetail(id) {
            this.$router.push({ name: this.route_name, params: { id: id } })
        },

        itemClasses() {
            return ''
        },
        load(refresh = true) {
            var self = this

            self.$overlay.loading()

            // If we are filtering by states and we aren't at detail
            self.$store
                .dispatch('issues/loadAll', {
                    date: self.date
                })
                .then(function() {
                    self.$store.commit('issues/filterByState', { states: self.showByState })

                    if (refresh) {
                        self.$bar.show()
                        self.$bar.reset()

                        if (self.$route.params.id == undefined) {
                            self.detail = false
                        } else if (self.$route.params.id != undefined) {
                            if (!self.$store.getters['issues/getById'](self.$route.params.id)) {
                                self.$store
                                    .dispatch('issues/loadById', {
                                        id: self.$route.params.id
                                    })
                                    .then(function() {
                                        self.detail = self.$route.params.id
                                    })
                            } else {
                                self.detail = self.$route.params.id
                            }

                            self.$bar.addAction('back', {
                                customClass: 'left',
                                callback: function() {
                                    self.$router.push(self.route)
                                    self.detail = false
                                },
                                label: self.$t('menu_aux.action.back')
                            })
                        }
                    }
                    if (!self.detail) {
                        self.$bar.show()
                        self.$bar.reset()

                        self.barActionsMain()
                    }

                    self.$overlay.hide()
                })
        },

        barActionsMain() {
            var self = this
            if (Object.keys(this.issues).length > 0 && this.userLogged && (this.userLogged.password || this.userLogged.app_access)) {
                self.$bar.addAction('create', {
                    callback: function() {
                        if (!self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                            self.$popup.login({
                                list: self.$store.getters['employee/getLoginUsers'](1),
                                callAfterAccess: function(response) {
                                    if (response) {
                                        self.$router.push({ name: self.route_name })
                                        self.$popup.close()
                                    }
                                }
                            })
                        } else {
                            var data = self.$store.getters['issues/getTemplates']

                            self.$popup.select({
                                list: data,
                                cType: self.type,
                                title: self.$t('popup.add_template.title'),
                                listEmpty: self.$t('popup.add_template.list_empty'),
                                textSave: self.$t('popup.add_template.text_save'),
                                textSaveStart: self.$t('popup.add_template.text_save_start'),
                                callSave: function(pSelf, store) {
                                    if (typeof pSelf.selectedEl !== 'undefined') {
                                        return store
                                            .dispatch('issues/add', {
                                                tpl_id: pSelf.selectedEl,
                                                cType: self.type
                                            })
                                            .then(function(response) {
                                                if (response) {
                                                    self.setAskTitle = true
                                                    pSelf.selectedEl = response.id
                                                } else {
                                                    self.$popup.close()
                                                }

                                                return Promise.resolve(pSelf, store)
                                            })
                                    } else {
                                        return Promise.resolve(pSelf, store)
                                    }
                                },
                                callSaveStart: function(pSelf, store) {
                                    self.$router.push({
                                        name: self.route_name,
                                        params: { id: pSelf.selectedEl }
                                    })
                                    return Promise.resolve(pSelf, store)
                                }
                            })
                        }
                    },
                    label: self.$t('menu_aux.action.create')
                })
            }
        },

        updateFilters() {
            let self = this

            if (this.date[2] == 'range') {
                this.$popup.dateRange({
                    title: self.$t('popup.date_range.title'),
                    dateStart: null,
                    dateEnd: moment().format('YYYY-MM-DD'),
                    dateRange: self.date,
                    textSave: self.$t('popup.date_range.text_save'),
                    textCancel: self.$t('popup.date_range.text_cancel'),
                    callSave: function(pSelf, store) {
                        self.date[0] = pSelf.dateRange[0]
                        self.date[1] = pSelf.dateRange[1]

                        self.load(false)
                        self.$popup.close()
                    }
                })
            } else {
                this.load(false)
            }
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        }
    },
    filters: {
        hour: function(value) {
            if (value == parseInt(value, 10)) {
                return moment('00:00', 'HH:mm')
                    .add(value, 'hours')
                    .format('HH')
            } else {
                return moment('00:00', 'HH:mm')
                    .add(value, 'hours')
                    .format('HH:mm')
            }
        }
    },
    mounted() {
        let self = this

        Object.keys(self.issueStates).map(function(item_id, i) {
            var item = self.issueStates[item_id]
            if (item.default == 1) {
                self.showByState.push(item.id)
            }
        })
    },
    beforeDestroy() {
        this.$bar.hide()
    }
}
</script>

<style lang="scss">
#content.issues {
    $column-width: 20%;
    $column-max-width: 180px;
    $content-width: 80%;

    > .content {
        $content-margin: 12px;

        @include border-radius($default-border-radius);
        @include default-box-shadow();
        padding-top: 25px;
        background-color: $default-bg-color !important;
        margin: $content-margin 0 0 0;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            width: 100% !important;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }

        .total-items {
            @include font-size(s);
            font-family: $text-light;
            color: #666;
            font-family: $text;
            // text-transform: uppercase;
            width: 100%;
            margin-bottom: 6px;
        }

        .contain {
            width: 90%;
            margin: 0 auto;

            .empty {
                @include empty();
                @include background($size: 70px, $position: top center);
                padding-top: 80px;
                margin-top: calc(40% - #{$menu-top-height} - 70px);
                background-image: img('confeti_light.svg');
            }
        }
    }

    > .column.left {
        $content-space: 6px;

        width: $column-width !important;
        max-width: $column-max-width !important;
        background-color: transparent !important;
        margin-top: $alex-app-padding * 3;
        padding-bottom: 0 !important;
        height: calc(100% - (#{$alex-app-padding} * 3)) !important;
        overflow: visible !important;

        .title {
            @include font-size(sm, $important: true);
            color: #444;
            font-family: $text !important;
            text-align: center;
        }

        .select-date {
            margin: 6px auto 12px auto;
            padding: 0;
            width: 90%;

            .v-input__slot {
                @include border-radius(3px);
                // border: 1px solid rgba(0,0,0,0.2);
                background-color: darken($default-bg-color, 4%);
                height: rem(40px);

                &::before,
                &:after {
                    border: none !important;
                }
            }

            .v-select__slot {
                .v-select__selections {
                    padding: 3px 0px 3px 6px;
                    width: calc(100% - 18px);
                    overflow: hidden;

                    > input[readonly='readonly'] {
                        display: none;
                    }
                    .v-list__tile__content {
                        width: 100%;
                    }

                    .v-list__tile__title,
                    .v-list__tile__sub-title {
                        @include text-ellipsis();
                        height: 20px;
                        width: 100%;
                        font-family: $text-light;
                    }
                    .v-list__tile__title {
                        @include font-size(s);
                        line-height: 1;
                    }
                    .v-list__tile__sub-title {
                        @include font-size(s);
                        text-transform: uppercase;
                    }
                }
                .v-input__append-inner {
                    margin: 0;
                    padding: 0;
                }
                .v-input__icon {
                    width: 18px !important;
                    min-width: 18px !important;
                }
            }

            .v-menu {
                .v-menu__content {
                    @include hideScrollbar();
                    overflow: hidden;
                    width: 100%;
                    margin-top: rem(40px);
                }

                .v-list__tile {
                    padding: 0;
                }
                .v-list__tile__title {
                    @include font-size(sm);
                    font-family: $text-light;
                    padding: 0 9px;
                    word-wrap: break-word;
                }
            }
        }

        .issues-container {
            position: absolute;
            width: 100%;
            margin-top: 16px;

            .title {
                @include font-size(sm, $important: true);
                color: $main-text;
                text-align: center;
                font-family: $text;
                margin: 12px 0;
            }

            .select-date {
                @include display-flex();
                @include font-size(s);
                font-family: $text !important;
                color: #777 !important;
                line-height: rem(22px);
                height: 46px;
                border-bottom: none;
                width: 85%;
                margin: 0 auto;
                text-align: center;
                -webkit-appearance: auto;

                option {
                    font-family: $text;
                    // background-color: $main !important;
                    // color: #fff !important;
                }
            }

            .btn {
                @include background($color: transparent, $image: img('unchecked_aaaaaa.svg'), $size: 18px, $position: left 6px center);
                @include border-left-radius(3px);
                @include font-size(m);
                line-height: rem(22px);
                color: #777;
                font-family: $conden;
                display: block;
                width: calc(85% - 10px);
                margin: 12px 10px 12px auto;
                padding: 9px 12px;
                text-align: left;
                transition-duration: 0.1s;
                padding-left: 32px;

                &.selected {
                    @include default-box-shadow();
                    @include background($color: $default-bg-color, $image: img('checked_primary.svg'), $size: 18px, $position: left 6px center);
                    width: 85%;
                    margin: 12px -3px 12px auto;
                    clip-path: inset(-5px 2px -5px -5px);
                }
            }

            .btn.today {
                // border-color: #eee;
            }
        }
    }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.issues.portrait {
    padding: 15px 10px;

    .column {
        width: 100% !important;
        height: auto !important;
        max-width: 100% !important;
        margin-top: 0;

        .title {
            display: none;
        }

        .select-date {
            margin: 0%;
            width: 100%;
            padding: 0 10px;
            font-family: $text-light;
            text-align: left;
            -webkit-appearance: auto;
            .v-input__slot {
                background-color: $default-bg-color !important;
            }
        }
        .issues-container {
            position: relative;
            padding: 5px 10px;
            margin-top: 0px;

            .btn {
                width: 100%;
                @include font-size(s);
                background-color: #fff;
                box-shadow: none;
                margin: 5px 0;
                font-family: $text-light;
            }
        }
    }
    .content.right {
        width: 100% !important;
        box-shadow: none;
        overflow: hidden;
        padding-bottom: 50px !important;
        height: auto !important;

        .contain {
            width: 100%;
        }
    }
}
</style>
