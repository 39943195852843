<template>
    <div class="template-sections-container" :class="[$mq]">
        <div v-if="sections.length > 0" class="sidebar-sections hide-scrollbar">
            <div class="control-overflow" v-if="!isAudit">
                <p v-for="(section, index) in sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.item_id)" v-if="section.type != 9">
                    {{ section.title }}
                </p>
            </div>
            <div class="control-overflow" v-else>
                <p v-for="(section, index) in sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.section_id)">
                    {{ section.name }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TemplateSections',
    props: {
        sections: {
            type: undefined,
            default: false
        },
        isAudit: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toSection(id) {
            const section = document.getElementById(id)
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss" scoped>
.template-sections-container {
    width: 100%;
    height: 100%;
    .sidebar-sections {
        height: 40px;
        position: relative;
        background-color: $default-bg-color;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        // padding-right: 24px;

        .control-overflow {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            @include flex-direction(row);
            @include width-max-content();
            min-width: 100%;
        }

        .item {
            @include interaction();
            @include width-fit-content();
            @include text-ellipsis();
            @include font-size(sm);
            // background: purple;
            height: 100%;
            margin: 0 3px 0 0;
            padding: 9px;
            font-family: $text;
            text-decoration: underline;
            color: $main;
            cursor: pinter;
        }
    }
    &.portrait {
        .item {
            @include font-size(xs);
        }
    }
}
</style>
