<template>
    <div id="content" class="audit" :class="{ detail: addeditId }">
        <div v-if="!addeditId" class="content scrollbar full">
            <item v-if="!detailId" v-for="reg in templates" :item="reg" :auditsnoPhoto="auditsNoPhoto" @sendedId="openDetail" @sendedAddedit="createAudit"></item>
            <EmptyPage v-if="!detailId && Object.values(templates).length == 0" :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.audit_template_description')"></EmptyPage>

            <resume :hasError="errorAudit()" v-if="detailId && audit && !addeditId" :audit="audit" :detailId="detailId" @sendedAddedit="loadAuditEdit"></resume>
            <p v-if="detailId && audit && !addeditId && !audit" class="empty">
                {{ $t('audit.empty_templates') }}
            </p>
        </div>

        <detail v-if="detailId && addeditId" :cType="checkType" :gId="addeditId"></detail>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

import EmptyPage from '@/components/layout/EmptyPage'

import item from '@/components/domain/audit/item'

// Este detail es la tabla
import resume from '@/components/domain/audit/resume'
// Este detail es el comun de cheklist para crear
import detail from '@/components/domain/audit/detail'

import i18n from '@/i18n'

export default {
    name: 'Audit',
    components: { item, resume, detail, EmptyPage },
    data() {
        return {
            checkType: 2,
            detail: false,
            addedit: false,
            FORMAT: FORMAT,
            auditsNoPhoto: true
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },

        templates() {
            log('audtis templates -> ', this.$store.getters['audit/getTemplates'])
            var templates = this.$store.getters['audit/getTemplates']

            var result = {}
            var rolEmployee = this.$store.getters['employee/getRol'](this.user.rol)

            for (var templates_index in templates) {
                var template = templates[templates_index]
                if (template && template.config != false && template.config.roles && template.config.roles.length > 0) {
                    // Si el rol del usuario está en el listado del template o es nivel 8 se muestra
                    var templateroles = template.config.roles

                    if (templateroles.includes(this.user.rol) || rolEmployee.level == 8) {
                        result[templates_index] = template
                    }
                    // SI NO TIENE NINGUN ROL SE MUESTRA
                } else result[templates_index] = template
            }

            // DETECT IF ANY ITEM HAS A PHOTO
            var self = this
            var imageFound = Object.values(result).filter(obj => {
                return obj.image != false ? (self.auditsNoPhoto = false) : true
            })

            return result
        },
        audit() {
            // console.log(this.$store.getters["audit/getItems"]);
            return this.$store.getters['audit/getItems']
        },
        detailId: {
            get() {
                return this.detail
            },
            set(value) {
                this.detail = value
            }
        },
        addeditId: {
            get() {
                return this.addedit
            },
            set(value) {
                this.addedit = value
            }
        }
    },
    methods: {
        errorAudit() {
            if (this.templates.length == 0 || this.detailId == undefined) {
                return false
            }
            for (var template in this.templates) {
                var idx = this.templates[template]
                log(idx, this.detailId)
                if (idx.id == this.detailId) {
                    return false
                }
            }
            return true
        },
        load(refresh = true) {
            var self = this
            log('-----------------------------LOAD')
            log(self.$route.params.id)
            log(self.$route.params.edit)
            log('-----')
            this.$bar.reset()
            if ((this.user.app_access || this.user.password) && self.$store.getters['employee/getRol'](this.user.rol).level < 8) {
                this.$bar.addAction('create', {
                    callback: function() {
                        self.listAudits()
                    },
                    label: self.$t('popup.add_template.text_save')
                })
            }

            self.detailId = self.$route.params.id
            //   self.addeditId = self.$route.params.edit

            if (self.detailId && !self.$route.params.edit) {
                // console.log(" - hay detail (tabla)");
                self.addeditId = false
                self.$store.dispatch('audit/loadItems', { template: self.detailId }).then(function() {
                    self.openDetail(self.detailId)
                })
            } else if (self.detailId && self.$route.params.edit) {
                log(' - hay addedit')
                self.$store.dispatch('audit/loadById', { id: self.$route.params.edit }).then(function() {
                    log('call openAdd')
                    self.addeditId = self.$route.params.edit
                    self.openAddedit(self.addeditId)
                })
            } else {
                self.addeditId = false
            }
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        openDetail(id) {
            var self = this

            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0)) {
                self.goToDetail(id)
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response) {
                            self.goToDetail(id)
                            self.$popup.close()
                        }
                    }
                })
            }
        },
        goToDetail(id) {
            log(' >> openDetail', id)
            var self = this

            if (!self.detailId) {
                // console.log("Hace la redireccion");
                self.$router.push({ name: 'AuditDetail', params: { id: id } })
            } else {
                // console.log("Continua con sus cosas de open detail");
                self.$bar.reset()

                self.$bar.addAction('back', {
                    callback: function() {
                        self.$router.push({ name: 'Audit' })
                    },
                    label: self.$t('menu_aux.action.back')
                })
                var template = this.templates[this.$route.params.id]
                var templateroles = template.config.roles ? template.config.roles : false
                if (Object.values(this.audit).length > 0 && templateroles ? templateroles.includes(this.user.rol) : true && self.$store.getters['employee/getRol'](this.user.rol).level != 8) {
                    self.$bar.addAction('create', {
                        callback: function() {
                            self.createAudit(id)
                        },
                        label: self.$t('menu_aux.action.create')
                    })
                }
            }
        },

        createAudit(id) {
            var self = this
            log(' >> createAudit', id)
            self.$overlay.loading()
            self.$store.dispatch('audit/loadItems', { template: id }).then(function() {
                var audit_tmpl = self.$store.getters['audit/getTemplateById'](id)
                var roles = typeof audit_tmpl.config.roles !== 'undefined' && audit_tmpl.config.roles != '' ? audit_tmpl.config.roles.toString() : []
                var level = typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3

                var empl_curr = self.$store.getters['loginUser/getLocalEmployee']
                empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)

                if (roles.length == 0 || roles.includes(empl_curr.rol)) {
                    // if (self.$store.getters['hasAccess'](self.currentEmployee().id, role)) {
                    // self.$router.push({
                    //   name: 'AuditEdit',
                    //   params: { id: id, edit: 'add' }
                    // })
                    self.$store.dispatch('audit/changeSupervisor', { emp_id: self.currentEmployee().id })
                    self.$popup.close()

                    self.$store
                        .dispatch('audit/add', {
                            tpl_id: id,
                            cType: audit_tmpl.type,
                            emp_ref: self.currentEmployee().id
                        })
                        .then(function(response) {
                            // console.log(response);
                            // console.log(response.id);
                            self.addeditId = response.id
                            // console.log("ID RESULTANTE: "+self.addeditId);
                            // console.log("Hace la redireccion");
                            self.$router.push({
                                name: 'AuditEdit',
                                params: { id: id, edit: self.addeditId }
                            })
                            self.$overlay.hide()
                        })
                } else {
                    self.$overlay.hide()
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.audits.not_level_permission'),
                        textSave: self.$t('popup.audits.exit'),
                        callSave: function(response) {}
                    })
                }
            })
        },

        loadAuditEdit(id) {
            if (typeof id === 'undefined') {
                var templateID = this.$route.params.id
                this.createAudit(templateID)
            } else {
                this.addeditId = id
                this.openAddedit(id)

                this.$router.push({
                    name: 'AuditEdit',
                    params: { id: this.detailId, edit: this.addeditId }
                })
            }
        },

        openAddedit(id) {
            log(' >> openAddedit', id, this.addeditId)
            var self = this
            // self.$bar.reset();
            if ((id == self.addeditId && typeof id !== 'undefined' && id != 'add') || (typeof self.addeditId !== 'undefined' && self.addeditId != 'add')) {
                log('Continua con sus cosas de ADDEDIT')
                self.$bar.reset()

                self.$bar.addAction('back', {
                    callback: function() {
                        self.$router.push({
                            name: 'AuditDetail',
                            params: { id: self.detailId }
                        })
                    },
                    label: self.$t('menu_aux.action.back')
                })

                var audit = self.$store.getters['audit/getById'](id)
                var empl = audit.employee_ref.id
                var empl_curr = self.$store.getters['loginUser/getLocalEmployee']
                empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)
                var audit_tmpl = this.$store.getters['audit/getTemplateById'](self.detailId)
                var level = audit_tmpl.config != null && typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3

                log(empl, empl_curr, audit)
                if ((empl && empl_curr && empl_curr.id == empl) || (audit.complete_date && self.$store.getters['employee/getRol'](empl_curr.rol).level >= level) || self.$store.getters['employee/getRol'](empl_curr.rol).level == 8) {
                    //   var audit = self.$store.getters['audit/getById'](id)

                    // Quitar botón finalizar si el audit no tiene tu rol asignado
                    var template = this.templates[this.$route.params.id]
                    var templateroles = template.config.roles && template.config.roles.lenght > 0 ? template.config.roles : false
                    if (!audit.complete_date && !(audit.state == 'past' && !audit.def.can_edit.inPast) && !(audit.state == 'future' && !audit.def.can_edit.inFuture) && templateroles ? templateroles.includes(this.user.rol) : true && self.$store.getters['employee/getRol'](empl_curr.rol).level != 8) {
                        self.$bar.addAction('save', {
                            opened: true,
                            label: i18n.t('popup.audits.complete'),
                            callback: function() {
                                // console.log("doSave");
                                self.$popup.confirm({
                                    message: self.$t('popup.audits.confirm_save'),
                                    textSave: self.$t('popup.audits.save'),
                                    callSave: function() {
                                        var validation = []
                                        var oData = {
                                            cType: self.checkType,
                                            check: self.addeditId,
                                            emp: self.$store.getters['loginUser/getLocalEmployee'],
                                            date: moment().unix(),
                                            values: []
                                        }

                                        for (var i in self.$children[0].$children) {
                                            if (i > 0) {
                                                var itemTask = self.$children[0].$children[i]
                                                itemTask.hasError = false
                                                log(itemTask)
                                                if (itemTask.mandatedValidation) {
                                                    if (itemTask.mandatedValidation(itemTask.value)) {
                                                        validation.push(false)
                                                        itemTask.hasError = true
                                                        self.$snackbar.error({
                                                            message: self.$t('audit.save_error_uncomplete')
                                                        })
                                                    }
                                                    if (typeof itemTask.aId !== 'undefined' && typeof itemTask.value !== 'undefined') {
                                                        let value = itemTask.value
                                                        if (itemTask.def.item.itemsImage.includes(itemTask.item.type)) {
                                                            value = JSON.stringify(value)
                                                        }
                                                        oData.values.push({ item_id: itemTask.aId, value: value })
                                                    }
                                                }
                                            }
                                        }

                                        if (validation.indexOf(false) == -1) {
                                            oData.values = JSON.stringify(oData.values)
                                            self.$overlay.loading()

                                            // console.log(oData);
                                            self.$store.dispatch('audit/complete', oData).then(function(response) {
                                                self.$store.dispatch('audit/loadTemplates', {}).then(function() {
                                                    self.$overlay.hide()
                                                    self.$router.push({
                                                        name: 'AuditDetail',
                                                        params: { id: self.detailId }
                                                    })
                                                })
                                            })
                                        } else {
                                            log('CANNOT SAVE')
                                            log('-> oData')
                                            log(oData)
                                            log('-> validation')
                                            log(validation)
                                        }
                                    }
                                })
                            }
                        })
                    }
                } else {
                    self.$popup.alert({
                        closable: false,
                        blocking: false,
                        message: self.$t('popup.audits.not_level_permission'),
                        textSave: self.$t('popup.audits.exit'),
                        callSave: function(response) {
                            self.$router.push({
                                name: 'AuditDetail',
                                params: { id: self.detailId }
                            })
                        }
                    })
                }
            } else {
                var audit_tmpl = this.$store.getters['audit/getTemplateById'](self.detailId)
                var level = audit_tmpl.config != null && typeof audit_tmpl.config.level !== 'undefined' ? audit_tmpl.config.level : 3
                var list = false
                if (id != undefined) {
                    var empl = self.$store.getters['audit/getById'](id).employee_ref.id
                    if (empl && empl != null) {
                        empl = self.$store.getters['employee/getEmployee'](empl)
                    }
                    list = empl ? [empl] : self.$store.getters['employee/getAccessUsers'](level)
                } else {
                    list = self.$store.getters['employee/getAccessUsers'](level)
                }

                self.$popup.access({
                    list: list,
                    callAfterAccess: function(response) {
                        if (response) {
                            if (response.status) {
                                self.$popup.close()
                                self.$bar.reset()

                                self.$store.dispatch('audit/changeSupervisor', { emp_id: response.emp_id })

                                // console.log("ID QUE LLEGA: "+id);
                                if (id && typeof id !== 'undefined' && id != 'add') {
                                    self.addeditId = id
                                    // console.log("ID RESULTANTE: "+self.addeditId);
                                    // console.log("Hace la redireccion");
                                    self.$router.push({
                                        name: 'AuditEdit',
                                        params: { id: self.detailId, edit: self.addeditId }
                                    })
                                } else {
                                    self.$store
                                        .dispatch('audit/add', {
                                            tpl_id: self.detailId,
                                            cType: self.checkType,
                                            emp_ref: response.emp_id
                                        })
                                        .then(function(response) {
                                            // console.log(response);
                                            // console.log(response.id);
                                            self.addeditId = response.id
                                            // console.log("ID RESULTANTE: "+self.addeditId);
                                            // console.log("Hace la redireccion");
                                            self.$router.push({
                                                name: 'AuditEdit',
                                                params: { id: self.detailId, edit: self.addeditId }
                                            })
                                        })
                                }
                            } else {
                                log('Access error')
                            }
                        }
                    }
                })
            }
        },

        listAudits() {
            var self = this

            var empl_curr = self.$store.getters['loginUser/getLocalEmployee']
            empl_curr = self.$store.getters['employee/getEmployee'](empl_curr)

            this.$popup.listAudits({
                list: this.templates,
                level: parseInt(self.$store.getters['employee/getRol'](empl_curr.rol).level),
                callback: function(self2, store) {
                    self2.$popup.close()
                    self.createAudit(self2.selected)
                }
            })
        }
    },
    created() {
        // console.log("created");
        this.load()
    },
    watch: {
        $route: 'load'
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    beforeDestroy() {
        this.$bar.hide()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        $content-margin: 0px;

        @include display-flex();
        @include flex-direction(row);
        @include flex-wrap(wrap);
        @include align-content(flex-start);
        @include border-radius($default-border-radius);
        padding-top: 25px;
        background-color: $default-bg-color;
        margin: $content-margin 0 0 0;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: calc(100% - #{$content-margin}) !important;
        border: none;

        &.empty {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-end);
            padding-top: 75px;

            .message {
                @include display-inline-flex();
                @include align-items();
                @include background($image: img('alexpose_04.svg'), $size: contain, $position: right center);
                width: 100%;
                padding-right: 150px;
                height: 250px;
                max-height: 100%;
            }
        }
    }
}
</style>
