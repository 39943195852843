import { render, staticRenderFns } from "./column.vue?vue&type=template&id=64a3db92&scoped=true&"
import script from "./column.vue?vue&type=script&lang=js&"
export * from "./column.vue?vue&type=script&lang=js&"
import style0 from "./column.vue?vue&type=style&index=0&id=64a3db92&lang=scss&scoped=true&"
import style1 from "./column.vue?vue&type=style&index=1&id=64a3db92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a3db92",
  null
  
)

export default component.exports