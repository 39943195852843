<template>
    <div class="detail" :class="[$mq]">
        <!-- <div class="top-actions">
            <div class="top-info">
                <div class="title">
                    <h6 class="name">{{ template.name }}</h6>
                </div>
            </div>
        </div> -->
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail @backButton="back()" :hasMoreOptions="false" :title="template.name"></AppccTopDetail>
        </div>

        <div class="table" v-if="values.length > 0 && !hasError">
            <v-data-table class="datatable-container" :headers="headers" :items="values" :search="search" :pagination.sync="pagination" :rows-per-page-items="[10, 25, { text: $t('vtf.datatable.all-rows'), value: -1 }]" :no-data-text="$t('vtf.datatable.no-data')" :no-results-text="$t('vtf.datatable.no-data')" :rows-per-page-text="$t('vtf.datatable.rows-per-page')" hide-actions>
                <template v-slot:headers="props">
                    <tr>
                        <!-- <th class="column col-icon" width="30px"> -->
                        <!-- <v-icon @click="filterColumns(props.item.id)">search</v-icon> -->
                        <!-- </th> -->
                        <!-- <th class="column col-icon" width="30px">
              <button
                class="view-column"
                @click="filterColumns()"
                type="button"
                name="view_column"
              ></button>
            </th>-->
                        <th v-for="header in props.headers" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" v-show="header.visible" @click="changeSort(header.value)">
                            <v-icon small>arrow_upward</v-icon>
                            <span class="text keep-format" v-html="header.text"></span>
                        </th>
                    </tr>
                </template>

                <template v-slot:items="props">
                    <tr @click="sendAddedit(props.item.id)">
                        <!--  <td class="col-icon" width="30px">
              <v-icon size="18px">edit</v-icon>
            </td>-->
                        <!-- <td class="col-icon" width="30px"></td> -->
                        <td v-for="(column, index) in headers" v-show="column.visible" :data-type="column.type" :data-value="props.item[column.value]" class="column" :class="classes(props.item, props.item[column.value], column.type)" v-html="format(props.item[column.value], column.type)"></td>
                        <!-- @click="preview(props.item[column.value], column.type)" -->
                    </tr>
                </template>

                <template v-slot:no-results>{{ $t('vtf.datatable.no-result') }}</template>
            </v-data-table>
        </div>
        <EmptyPage v-else-if="values.length == 0 && !hasError" @buttonAction="sendAddedit" :title="$t('empty_pages.audit_title')" type="record" :description="$t('empty_pages.audit_description')" :buttonText="$t('empty_pages.start')"></EmptyPage>
        <EmptyPage v-if="hasError" :buttonText="$t('empty_pages.return_audits')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_audit')"></EmptyPage>
    </div>
</template>

<script>
import AppccTopDetail from '../../layout/AppccTopDetail.vue'
import EmptyPage from '../../layout/EmptyPage.vue'
import { FORMAT } from '@/constants'

export default {
    components: { EmptyPage, AppccTopDetail },
    props: {
        audit: { type: Object },
        detailId: { type: String },
        hasError: { type: undefined, default: false }
    },
    data() {
        return {
            infoIsOpen: false,
            search: '',
            pagination: {
                sortBy: 'status_order',
                rowsPerPage: 20,
                descending: false
            },
            hidden_columns: localStorage.col_audit ? JSON.parse(localStorage.col_audit) : [],
            FORMAT: FORMAT
        }
    },
    computed: {
        template() {
            // ESCONDER BOTTONES
            if (this.hasError) {
                this.$bar.hide()
            }
            return this.$store.getters['audit/getTemplateById'](this.detailId)
        },
        scoreClasses() {
            return {
                success: this.template.total_score == 100,
                progress: this.template.total_score > 75 && this.template.total_score < 100,
                warning: this.template.total_score > 0 && this.template.total_score <= 75,
                error: this.template.total_score == 0
            }
        },

        gValues() {
            let self = this
            let values = [0, 0]
            let kAudits = Object.keys(self.audit)

            if (kAudits.length == 1) {
                let aud = self.audit[kAudits[0]]
                values = [0, aud.score]
            } else if (kAudits.length > 1) {
                values = []

                Object.keys(self.audit).map(function(aud_id, e) {
                    var aud = self.audit[aud_id]
                    values.push(aud.score)
                })
                values.reverse()
            }

            return [
                {
                    name: '',
                    data: values
                }
            ]
        },

        headers: {
            get: function() {
                let schema = this.$store.getters['audit/getHeaders']

                var headers = []
                headers.push({
                    text: this.$t('audit.headers.status'),
                    value: 'status',
                    type: 'status',
                    sortable: true,
                    visible: true,
                    blocked: true
                })
                headers.push({
                    text: this.$t('audit.headers.date'),
                    value: 'date',
                    sortable: true,
                    visible: true,
                    blocked: true
                })
                headers.push({
                    text: this.$t('audit.headers.supervised'),
                    value: 'employee',
                    type: 'employee',
                    sortable: true,
                    visible: true,
                    blocked: true
                })
                headers.push({
                    text: this.$t('audit.headers.score'),
                    value: 'score',
                    type: 'score',
                    sortable: true,
                    visible: true,
                    blocked: true
                })

                // for (var i in schema) {
                //   var raw = schema[i];

                //   var column = {
                //     text: raw.title,
                //     value: raw.id,
                //     sortable: true,
                //     type: raw.type,
                //     //visible: true
                //     visible: this.hidden_columns.indexOf(raw.id) != -1 ? false : true,
                //     blocked: false
                //   };
                //   headers.push(column);
                // }

                return headers
            },
            set: function(value) {
                this.headers = value
            }
        },
        values() {
            var values = []

            for (var i in this.audit) {
                var raw = this.audit[i]
                var row = {}
                // row.edit = "";
                // row.select = "";
                row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'
                row.date = raw.created_date ? "<span style= 'display:none'>" + raw.created_date + '</span>' + moment(raw.created_date).format('DD [' + this.$t('calendar.separator') + '] MMMM [' + this.$t('calendar.separator') + '] YYYY') : '-'
                // row.employee = Object.keys(raw.employees).length > 0 ? "<div class='avatar' style=\"background-image: url('"+(raw.employees[Object.keys(raw.employees)[0]].avatar).trim()+"')\"></div><div class='name'>" + raw.employees[Object.keys(raw.employees)[0]].name + "</div>" : "-";
                // console.log(
                //   '000'.substring(0, 3 - '' + raw.score.length) + '' + raw.score
                // )
                row.employee = raw.employee_ref && raw.employee_ref != null ? '<span class="user-color"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + '" /></span><div class="name">' + raw.employee_ref.name + '</div>' : Object.keys(raw.employees).length > 0 ? '<span class="user-color> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee_ref.avatar + "></span><div class='name'>" + raw.employees[Object.keys(raw.employees)[0]].name + '</div>' : '-'
                row.score = raw.total ? "<span style= 'display:none'>" + this.addZeros(raw.score, 3) + '</span>' + raw.completed + "<span class='total'>/" + raw.total_score + " <span class='percent'>(" + raw.score + '%)</span></span>' : '-'
                row.id = raw.id ? raw.id : ''
                switch (raw.status) {
                    case 'completed':
                        row.status_order = '3'
                        break
                    case 'progress':
                        row.status_order = '1'
                        break
                    case 'scheduled':
                        row.status_order = '2'
                        break
                    default:
                        row.status = '0'
                        break
                }
                row.status = raw.status ? "<span class='status'>" + this.$t('audit.status.' + raw.status) + '</span>' : '-'

                values.push(row)
            }

            return values
        }
    },
    methods: {
        back() {
            this.$router.push({ name: 'Audit' })
        },
        filterColumns() {
            var self = this
            this.$filter({
                list: this.headers,
                selected: self.hidden_columns,
                callback: function(d) {
                    self.hidden_columns = [...d]
                    self.saveFilter(self.hidden_columns)
                }
            })
            this.$filter.show()
        },

        // Method to format numbers to order correctly
        addZeros(number, length) {
            var str = '' + number
            while (str.length < length) {
                str = '0' + str
            }
            return str
        },

        saveFilter(filter) {
            if (localStorage) {
                localStorage.col_audit = JSON.stringify(filter)
            }
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        sendAddedit(id) {
            this.$emit('sendedAddedit', id)
        },

        format(value, type) {
            switch (type) {
                case String(FORMAT.CHECK):
                case String(FORMAT.YESNO):
                case String(FORMAT.YESNONC):
                    return value

                case String(FORMAT.DATE):
                    return moment(value).format('DD/MM/YYYY')

                case String(FORMAT.DATETIME):
                    return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''

                case String(FORMAT.PHOTO):
                case String(FORMAT.SIGNATURE):
                    return value ? '<img src="' + value + '"/>' : value

                case String(FORMAT.TITLE):
                case String(FORMAT.SUBTITLE):
                    return value || '-'

                default:
                    return value
            }
        },
        classes(value, current, type) {
            let val = value

            switch (type) {
                case 'score':
                    return {
                        score: val != '-'
                    }

                case String(FORMAT.TITLE):
                case String(FORMAT.SUBTITLE):
                    val = current ? current.replace('%', '') : 0

                    return {
                        success: val == 100,
                        progress: val > 75 && val < 100,
                        warning: val > 0 && val <= 75,
                        error: val == 0
                    }

                case 'status':
                    return {
                        completed: value.status.includes(this.$t('audit.status.completed')),
                        progress: value.status.includes(this.$t('audit.status.progress')),
                        scheduled: value.status.includes(this.$t('audit.status.scheduled'))
                    }

                default:
                    return ''
            }
        },
        preview(value, type) {
            switch (parseInt(type)) {
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    this.$popup.media({ image: value })
                    break
                default:
                    break
            }
        },

        employee(id) {
            return this.$store.getters['employee/getEmployee'](id)
        }
    },
    filters: {
        moment: function(date) {
            return date ? moment(date).fromNow() : '--'
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    > .content {
        padding-top: 0 !important;

        .detail {
            width: inherit;
            height: 100%;

            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 11px 12px 12px 11px;
                max-height: 150px;
            }
        }
        .table {
            @include border-radius(5px);
            width: calc(100% - #{$alex-app-padding * 2});
            margin-right: $alex-app-padding;
            overflow: auto;
            float: right;
            padding: 6px;
            height: calc(100% - 65px);
        }

        .v-table {
            thead {
                tr {
                    height: 50px;
                }

                .col-icon {
                    padding: 15px 12px;
                }
                .column,
                .column > .text {
                    @include font-size(m);
                    font-family: $conden-bold;
                    color: $main;
                    text-align: center;
                }
                .column > .text {
                    @include text-ellipsis();
                }

                .col-icon {
                    padding: 0 !important;

                    .view-column {
                        @include background($image: img('view_column_777777.svg'), $size: 20px);
                        width: 40px;
                        height: 40px;
                        margin: 5px 0;
                        padding: 0;
                    }
                }
            }

            tbody {
                .col-icon {
                    padding: 15px 12px;
                }
                .column {
                    text-align: center;

                    &[data-type='1'],
                    &[data-type='2'],
                    &[data-type='3'] {
                        @include background($size: 24px);
                        color: transparent;
                    }

                    &[data-type='1'][data-value='0'] {
                        background-image: img('error_aaaaaa.svg');
                    }
                    &[data-type='1'][data-value='1'] {
                        background-image: img('checked_ok.svg');
                    }

                    &[data-type='2'][data-value='0'],
                    &[data-type='3'][data-value='0'] {
                        background-image: img('error_alert.svg');
                    }
                    &[data-type='2'][data-value='1'],
                    &[data-type='3'][data-value='1'] {
                        background-image: img('checked_ok.svg');
                    }
                    &[data-type='3'][data-value='2'] {
                        background-image: img('question_aaaaaa.svg');
                    }
                    &[data-type='score'] {
                        @include font-size(l);
                        color: $main;
                        font-family: $text;
                        text-align: center;

                        .total {
                            @include font-size(m);
                            font-family: $text-bold;
                            color: $main;
                        }
                        .percent {
                            // TODO Aplicar formato a todas
                            // @include font-size(s);
                            // font-family: $text-bold;
                            // color: $neutro-s50;
                        }
                    }

                    &[data-type='status'] {
                        .status {
                            padding: 2px 5px;
                            border-radius: 3px;
                        }
                        &.completed .status {
                            background-color: $main;
                            color: white;
                        }
                        &.progress .status {
                            background-color: $warning;
                            color: white;
                        }
                        &.scheduled .status {
                            background-color: $info;
                            color: $main;
                        }
                    }

                    &[data-type='employee'] {
                        .user-color {
                            $user-size: rem(40px);
                            @include dftUserColor();
                            @include text-ellipsis();
                            width: $user-size - rem(19px);
                            height: $user-size - rem(19px);
                            min-width: rem(10px);
                            min-height: rem(10px);
                            margin-right: rem(5px);
                            &:after {
                                padding: ($user-size / 6) 0;
                                font-size: 10px;
                            }
                            position: relative;
                            top: rem(5px);
                        }
                        /*.avatar {
              @include background(  $position: center, $size: 22px );
              width: 22px;
              height: 22px;
              display: inline-block;
              vertical-align: top;
              border-radius: 50px;
              margin-right: 5px;
            }*/
                        .name {
                            display: inline;
                            height: 22px;
                            line-height: 22px;
                        }
                    }

                    img {
                        height: 21px;
                    }
                }
            }

            .column {
                @include text-ellipsis();
                max-width: 300px;
                padding: 12px 9px;
            }
        }
    }
}
</style>

<style lang="scss">
#content.audit {
    > .content {
        > .detail.portrait {
            width: 100%;
            height: 100%;
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px 6px 10px 0px;
                max-height: 150px;
            }

            .table {
                height: calc(100% - 125px);
                padding: unset;
                position: relative;
                left: 8px;
                width: 100%;
            }
        }
    }
}
</style>
