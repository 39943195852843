<template>
    <div class="item slideRight" :class="{ w_picture: item.image && item.num_progress == 0, w_progress: !item.image && item.num_progress > 0, extended: item.image && item.num_progress > 0 }" @click="sendedId(item.id)">
        <div class="list">
            <template v-if="!auditsNoPhoto">
                <div v-if="item.image" class="picture" :style="{ backgroundImage: 'url(' + item.image + ')', backgroundSize: 'cover' }"></div>
                <div v-else class="picture"></div>
            </template>
            <h6 class="name">{{ item.name }}</h6>
            <span class="subname"
                ><template v-if="item.last_update"
                    >{{ $t('audit.last_time') }} {{ item.last_update | moment }} {{ $t('audit.last_result') }} <span class="total" v-if="item.last_update">{{ item.last_audit.completed }}/{{ item.last_audit.total_score }} ({{ item.last_audit.score }}%)</span></template
                ><template v-else>{{ $t('audit.empty_last_audit') }}</template></span
            >
            <div class="progress" v-if="item.num_progress > 0">{{ item.num_progress }} {{ $t(item.num_progress > 1 ? 'audit.num_progress_pl' : 'audit.num_progress_sg') }}</div>
        </div>
        <div class="nav"></div>
    </div>
</template>
<script>
export default {
    props: {
        item: { type: Object },
        auditsNoPhoto: { type: Boolean }
    },
    data() {
        return {}
    },
    computed: {
        scoreClasses() {
            return {
                success: this.item.last_score == 100,
                progress: this.item.last_score > 75 && this.item.last_score < 100,
                warning: this.item.last_score > 0 && this.item.last_score <= 75,
                error: this.item.last_score == 0
            }
        },
        calcScore() {
            this.item.last_score = this.item.last_score != 0 ? this.item.last_score : 1
            var score = ((this.item.completed * 100) / this.item.last_score).toFixed(1)
            if (score == parseInt(score)) return parseInt(score)
            else return score
        }
    },
    methods: {
        sendedId(id) {
            this.$emit('sendedId', id)
        },
        sendedAddedit(id) {
            this.$emit('sendedAddedit', id)
        }
    },
    filters: {
        moment: function (date) {
            return date ? moment(date).fromNow() : '--'
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
    @include interaction();
    @include border-radius();
    @include display-flex();
    @include align-items();
    @include flex-wrap(wrap);
    background-color: #fff;
    width: calc(50% - 24px);
    height: auto;
    margin: 12px;
    overflow: hidden;
    padding: 3px;
    position: relative;
    @include bgHover(#fff);
    .list {
        width: calc(100% - 70px);
        height: auto;
        padding: 6px;
        .picture {
            @include border-radius(3px);
            @include background($color: $neutro-t50, $image: img('image_neutro.svg'), $size: 60px);
            height: 100px;
            width: 100%;
            margin-bottom: 10px;
        }
        .name {
            @include font-size(l);
            margin: 0;
            font-family: $text-bold;
            color: $main-s30;
            width: calc(100% - 50px);
            height: auto;
            max-height: calc(100% - 24px);
            display: inline-block;
        }
        .subname {
            @include font-size(s);
            height: auto;
            max-width: calc(100%);
            padding: 3px 0px;
            display: inline-block;
            .total {
                font-family: $text-bold;
            }
        }
        .progress {
            width: fit-content;
            padding: 2px 10px 2px 25px;
            background-color: $warning;
            border-radius: 5px;
            color: #ffffff;
            font-family: $text-bold;
            @include font-size(s);
            margin-top: 10px;
            @include background($image: img('edit_ffffff.svg'), $position: left 5px center, $size: 15px);
        }
    }
    .nav {
        position: absolute;
        right: 0;
        width: 70px;
        height: 100%;
        @include background($image: img('right_dark.svg'), $position: center center, $size: 20px);
    }
}
@include media('>phone', '<=tablet') {
    .item {
        width: calc(100% - 24px);
        margin: 12px;
    }
}
@include media('>tablet', '<desktop') {
    .item {
        width: calc(50% - 24px);
        margin: 12px;
    }
}
@include media('>=desktop') {
    .item {
        width: calc(33% - 24px);
        margin: 12px;
    }
}
</style>
