<template>
    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="$mq">
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail :hasMoreOptions="true" @deleteButton="deleteIssue()" @infoOpened="infoOpened" @backButton="back()" :userInfo="check.employee" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :sections="sections"></Template-sections>
        </div>
        <div class="resume">
            <span class="count" v-if="issueStates">
                <select v-if="issueStates" class="check-status" :class="'status-' + check.status" :data-status="check.status" @change="changeState">
                    <option v-for="state in issueStates" :value="state.id" :selected="check.status == state.id">{{ state.name }}</option>
                </select>
                <select v-else class="check-status" disabled>
                    <option value="0" selected="true">{{ $t('issues.no_state') }}</option>
                </select>
            </span>

            <!-- <span class="toggle-info" :class="{ opened: collapsedInfo }" @click="toggleInfo"></span> -->
        </div>
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 1 }, { 'opened-info': infoIsOpen }]">
            <!-- <div class="top-info">
                <div class="content">
                    <span class="item-name">
                        <span v-if="check.issue_id" class="item-id">{{ check.issue_id }}</span>
                        {{ check.title }}
                    </span>
                    <span class="item-time">
                        <template v-if="check.created_date">
                            <div class="hour">
                                {{ check.created_date | moment('ddd DD MMM') }}
                            </div>
                            <div class="date">
                                {{ check.created_date | moment('HH:mm') }}
                            </div>
                        </template>
                    </span>
                    <span class="item-user">
                        <UserTag :userId="check.employee.id" size="medium" styleOf="block" :hasAvatar="true"></UserTag>
                    </span>
                </div>

                <div class="resume">
                    <span class="count" v-if="issueStates">
                        <select v-if="issueStates" class="check-status" :class="'status-' + check.status" :data-status="check.status" @change="changeState">
                            <option v-for="state in issueStates" :value="state.id" :selected="check.status == state.id">{{ state.name }}</option>
                        </select>
                        <select v-else class="check-status" disabled>
                            <option value="0" selected="true">{{ $t('issues.no_state') }}</option>
                        </select>
                    </span>

                    <span class="toggle-info" :class="{ opened: collapsedInfo }" @click="toggleInfo"></span>
                </div>

                <div v-if="sections.length > 0" class="sidebar-sections hide-scrollbar">
                    <div class="control-overflow">
                        <p v-for="(section, index) in sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.item_id)" v-if="section.type != 9">
                            {{ section.title }}
                        </p>
                    </div>
                </div>
            </div> -->

            <div v-if="collapsedInfo" class="list-info" :class="{ 'with-sections': sections.length > 0 }">
                <span class="action delete" @click="deleteIssue()">{{ $t('issues.actions.delete') }}</span>
            </div>

            <div class="exam performing">
                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <template v-if="check.data && check.data.ref && loadRef">
                    <div class="message-container">
                        <Message :message="getStringVariables('answer.reference.task', refTask)" :buttonLabel="getString('answer.reference.task_action')" @buttonAction="goToReference" type="info"></Message>
                    </div>
                    <!-- <div v-else class="reference-link" @click="goToReference">
                        <div class="alex"></div>
                        <div class="message keep-format" @click="goToReference" v-html="getStringVariables('answer.reference.task', refTask)"></div>
                        <div class="read-more">
                            {{ getString('answer.reference.task_action') }}
                        </div>
                    </div> -->
                </template>

                <template v-if="check">
                    <answer v-for="answer in check.items" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type"></answer>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="content hide-scrollbar right empty">
        <!-- <div class="message empty">
            {{ getString('issues.empty') }}
        </div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_issues')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_issues')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '../../layout/EmptyPage.vue'
import { FORMAT } from '@/constants'
import answer from '@/components/domain/tasks/answer'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import UserTag from '@/components/layout/UserTag'
import Message from '@/components/domain/ui/Message'
import TemplateSections from '@/components/layout/TemplateSections'

export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, Message, TemplateSections },
    props: {
        gId: { type: String },
        askTitle: { type: Boolean, default: false }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 4,
            refTask: {},
            FORMAT: FORMAT,
            loadRef: false,
            infoIsOpen: false,
            view_more: false
        }
    },
    computed: {
        done() {
            return parseInt((this.check.completed / this.check.total) * 100)
        },
        check() {
            let result = this.$store.getters['issues/getById'](this.gId)
            if (!this.refCheck || this.refCheck == '') {
                this.loadReference(result)
            }
            // Esconder botones en caso de que aparezca el error
            if (result == undefined) {
                this.$bar.hide()
            }
            return result
        },
        sections() {
            var sections = []
            for (var i in this.check.items) {
                if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                    sections.push(this.check.items[i])
                }
            }
            return sections
        },
        leftTime() {
            return this.$root.timeLeft(this.check.schedule.end)
        },

        issueStates() {
            return this.$store.getters['getIssueStates']
        }
    },
    methods: {
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$router.push({ name: 'Issues' })
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        format(value, type) {
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                case FORMAT.YESNONC:
                    return value

                case FORMAT.DATE:
                    return moment(value).format('DD/MM/YYYY')

                case FORMAT.DATETIME:
                    return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''

                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    return value ? '<img src="' + value + '"/>' : value

                default:
                    return value
            }
        },

        toggleInfo() {
            this.collapsedInfo = !this.collapsedInfo
        },

        deleteIssue() {
            var self = this
            var employee = self.currentEmployee()

            if (!self.$store.getters['hasAccess'](employee.id, 1)) {
                self.$popup.access({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response.status) {
                            self.$popup.close()
                            self.popupDeleteIssue(response.emp_id)
                        }
                    }
                })
            } else {
                self.popupDeleteIssue(employee.id)
            }
        },
        popupDeleteIssue(emp_id) {
            var self = this
            var relItem = self.refTask ? self.refTask.task_id : false

            self.$popup.delete({
                message: self.$t('issues.delete_msg', { issue: self.check.title }),
                textSave: self.$t('popup.delete.text_save'),
                textCancel: self.$t('popup.delete.text_cancel'),
                callSave: function() {
                    self.$store.dispatch('issues/delete', { check: self.gId, emp: emp_id, ref_item: relItem }).then(function(response) {
                        if (response) {
                            self.$popup.close()
                            self.$snackbar.success({ duration: 4000, closeable: true, message: self.$t('issues.deleted_msg') })

                            self.$store.dispatch('tasks/updateIssue', { check: self.refTask.check_id, answer: self.refTask.task_id, toDelete: true })

                            self.$router.push({ name: 'Issues', params: {} })
                        }
                    })
                }
            })
        },

        changeState(event) {
            if (typeof this.check !== 'undefined') {
                this.$store.dispatch('issues/update', { check: this.gId, status: event.target.value })
                this.$store.dispatch('tasks/updateIssue', { check: this.refTask.check_id, answer: this.refTask.task_id, status: event.target.value })
            }
        },
        toSection(id) {
            console.error(1234)
            const section = document.getElementById(id)

            switch (this.$mq) {
                case 'desktop':
                case 'landscape':
                    document.getElementById('detail-content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
                case 'portrait':
                    document.getElementById('content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
            }
        },
        loadReference(checklist) {
            log(checklist)
            if (checklist != undefined && checklist && checklist.data && checklist.data.ref && checklist.data.ref.type) {
                let self = this
                let refCheck = checklist.data.ref.checklist
                let refTask = checklist.data.ref.item
                log(checklist.data.ref)
                log(this.referenceType(checklist.data.ref.type))

                self.$store
                    .dispatch(this.referenceType(checklist.data.ref.type) + '/loadById', {
                        id: refCheck
                    })
                    .then(function() {
                        var response = self.$store.getters[self.referenceType(checklist.data.ref.type) + '/getById'](refCheck)
                        if (response) {
                            self.loadRef = true
                            var section = false
                            var task_title = ''
                            var task_id = ''
                            var task_value = ''
                            if (checklist.data.ref.type == 2) {
                                for (var k in response.sections) {
                                    var sec = response.sections[k]
                                    section = typeof sec.items[refTask] !== 'undefined' ? k : false
                                }

                                if (section) {
                                    task_title = response.sections[section].items[refTask].title
                                    task_id = response.sections[section].items[refTask].item_id
                                    task_value = self.format(response.sections[section].items[refTask].value, response.sections[section].items[refTask].type)
                                }
                            } else {
                                task_title = response.items[refTask].title
                                task_id = response.items[refTask].item_id
                                task_value = self.format(response.items[refTask].value, response.items[refTask].type)
                            }
                            self.refTask = {
                                check_id: response.id,
                                check_title: response.title,
                                check_business_date: response.business_date,
                                check_date: moment(response.business_date).format('dddd DD MMM YYYY'),
                                task_title: task_title,
                                task_id: task_id,
                                task_value: task_value
                            }
                        }
                    })
            } else {
                this.loadRef = false
            }
        },
        goToReference() {
            if (this.check && this.check.data && this.check.data.ref) {
                var type = this.referenceType(this.check.data.ref.type)
                var info = this.$store.getters[type + '/getById'](this.check.data.ref.checklist)
                log(type, info)

                if (type == 'tasks') {
                    this.$router.push({
                        name: 'ChecklistDetail',
                        params: {
                            id: this.check.data.ref.checklist,
                            checkType: 1,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date)
                        }
                    })
                } else if (type == 'audit') {
                    this.$router.push({
                        name: 'AuditEdit',
                        params: {
                            id: info.template_id,
                            checkType: 2,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date),
                            edit: this.check.data.ref.checklist
                        }
                    })
                } else if (type == 'register') {
                    this.$router.push({
                        name: 'RegisterEdit',
                        params: {
                            id: info.template_id,
                            checkType: 2,
                            toTaskId: this.check.data.ref.item,
                            propDate: moment(this.refTask.check_business_date),
                            edit: this.check.data.ref.checklist
                        }
                    })
                }
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        referenceType(type) {
            switch (parseInt(type)) {
                case 1:
                    return 'tasks'
                case 2:
                    return 'audit'
                case 3:
                    return 'register'
            }
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },

    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.issues.detail {
    $column-width: 20%;
    $column-max-width: rem(60px);
    $content-width: 80%;

    > .content {
        $top-info: rem(90px);
        $resume-width: rem(150px);
        $detail-text-color: $main-dark;
        $top-info: rem(70px);
        $resume-width: rem(100px);
        overflow: hidden !important;
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;

        .detail-box {
            $sidebar-size: rem(40px);
            $collapse-button: rem(40px);
            $content-padding: rem(0px);
            $detail-bg: $default-bg-color;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            padding-bottom: $menu-aux-height;
            width: 100%;
            background-color: $detail-bg;
            height: calc(100% - 120px);
            overflow: auto;

            &.with-sections {
                // padding-top: $top-info + $sidebar-size;
            }

            .list-info {
                $action-size: rem(60px);
                @include default-box-shadow();
                @include display-flex();
                @include justify-content(flex-end);
                @include align-items();
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                background-color: shade($default-bg-color, 5%);
                position: fixed;
                z-index: 9;
                top: $alex-app-padding + $menu-top-height + rem(11px) + $top-info;
                width: calc(100% - #{$column-max-width} - #{$menu-tools-min} - #{$content-padding} - #{$scroll-width});
                height: $action-size;

                &.with-sections {
                    top: $alex-app-padding + $menu-top-height + rem(11px) + $top-info + rem(40px);
                }

                .action {
                    @include font-size(xs);
                    font-family: $text-bold;
                    color: #444;
                    text-align: center;
                    display: block;
                    background-color: shade($default-bg-color, 7%);
                    width: $action-size;
                    height: $action-size;
                    padding: ($action-size - rem(20px)) 6px 2px 6px;
                    margin: 0 9px;
                    cursor: pointer;

                    &.delete {
                        @include background($image: img('delete_alert.svg'), $size: 24px, $position: top 9px center);
                    }
                }
            }

            .exam {
                @include border-radius($default-border-radius);
                // background-color: #fff;
                padding: 12px 0;
                height: 100%;

                &:not(.performing) {
                    // border: 2px solid #eee;
                    // background-color: #eee;
                    // filter: grayscale(70%);
                }
                &.performing {
                }

                & > .reference-link {
                    @include display-flex();
                    @include align-items();
                    @include background($color: shade($default-bg-color, 4%), $image: img('alert_primary.svg'), $position: left 20px center, $size: rem(35px));
                    width: 100%;
                    padding: 16px 20px 16px rem(80px);
                    margin: 0 auto 0 auto;

                    .message {
                        @include border-radius(3px);
                        @include font-size(s);
                        color: $main-s40;
                        display: inline-block;
                        padding-right: 12px;
                        overflow: visible;
                        width: calc(100% - #{rem(100px)});
                        max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
                        min-height: rem(40px);
                        height: auto;

                        > span {
                            @include keepFormat();
                            font-family: $text;
                        }
                        > .date {
                            @include font-size(s);
                            text-transform: capitalize;
                        }
                    }

                    .read-more {
                        @include border-radius(3px);
                        @include background($color: $main, $image: img('right_arrow_light.svg'), $position: right 6px center, $size: 20px);
                        @include font-size(s);
                        color: #fff;
                        font-family: $text;
                        padding: 9px 30px 9px 9px;
                        width: rem(100px);
                        display: inline-block;
                        background-color: $main;
                    }
                }
                .message-container {
                    width: 100%;
                    padding-left: 8px;
                }
            }
        }
        .resume {
            @include display-inline-flex();
            @include align-items();
            @include justify-content(flex-end);
            float: right;
            width: 100%;
            height: 55px;
            z-index: 1;
            position: relative;
            padding-right: 14px;
            padding-bottom: 10px;

            .count {
                @include font-size(sm);
                font-family: $text-bold;
                background-color: #fff;
                color: $detail-text-color;
                width: auto;
                max-width: calc(100% - #{rem(40px)});

                .check-status {
                    @include border-radius($default-border-radius);
                    @include font-size(inherit);
                    width: auto;
                    text-align: center;
                    border: none;
                    color: inherit;
                    font-family: inherit;
                    margin: 0;
                    padding: 6px 9px;
                }
                .status-1 {
                    color: $error;
                }
                .status-2 {
                    color: $warning;
                }
                .status-5 {
                    color: $done;
                }
            }

            .toggle-info {
                @include background($image: img('more_vert_aaaaaa.svg'), $size: 22px);
                display: inline-block;
                width: rem(40px);
                height: 100%;

                &.opened {
                    background-image: img('more_vert_primary.svg');
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('alexhead_06.svg'), $position: left 12px center, $size: 50px);
            width: 100%;
            padding: 16px rem(20px) 16px rem(80px);
            margin: 0 auto 0 auto;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: rem(40px);
                height: rem(40px);
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - #{rem(40px)} - #{rem(6px)});
                min-height: rem(20px);
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            padding: 12px;
            padding-bottom: 0px;
            height: auto;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px;
                padding-bottom: 0px;
                max-height: 95px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 173px);
                &.with-sections {
                    height: calc(100% - 220px);

                    &.opened-info {
                        height: calc(100% - 263px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 218px);
                }
            }
        }
        &.landscape {
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 170px);
                &.with-sections {
                    height: calc(100% - 210px);

                    &.opened-info {
                        height: calc(100% - 260px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 217px);
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;

        width: $column-width !important;
        max-width: $column-max-width !important;
        // height: calc( 100% - ( #{$alex-app-padding} * 3) ) !important;

        // .timeframes {
        //   .tframe {
        //     padding: 6px 0px 6px 6px;
        //
        //     .graph.chart {
        //       width: 30px;
        //       height: 30px;
        //     }
        //     .title.condensed {
        //       @include font-size(s, $important: true);
        //       @include text-ellipsis();
        //     }
        //
        //   }
        // }
    }
}
</style>

<style lang="scss">
#content.issues.detail.portrait {
    padding: 0px !important;
    > #detail-content {
        height: 100% !important;
        margin: 0px !important;

        .detail-box {
            padding-bottom: 0px !important;
            background: transparent;
            height: calc(100% - 105px);
            overflow: hidden;

            .top-info {
                width: 100%;
                top: 60px;
                left: 0;
                padding: 10px;
                background-color: $default-bg-color;
                height: auto;

                .content {
                    padding: 5px !important;
                    height: auto;
                    width: 100%;
                    max-width: 100%;

                    .item-name {
                        line-height: 1rem;
                        @include font-size(sm, $important: true);
                        height: 25px;
                    }
                    .item-user {
                        position: absolute;
                        .name {
                            @include font-size(xs, $important: true);
                        }
                    }
                    .item-time {
                        @include font-size(xs);
                        line-height: 0.75rem;
                        margin-top: -5px;
                    }
                }

                .sidebar-sections {
                    position: relative;
                    top: 0;
                    background: transparent;
                    .control-overflow {
                        justify-content: flex-start;
                        .item {
                            @include font-size(xs);
                        }
                    }
                }
            }

            .exam {
                height: 100%;
                padding-bottom: 0px;
                overflow: auto;

                .justify-description,
                .check-available {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                        width: calc(100% - 100px);
                    }
                    .action {
                        width: 100px;
                        @include font-size(xs);
                        line-height: 0.75rem;
                    }
                }
                .check-available {
                    .message {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .check-description {
                    padding: 8px 20px 8px 50px;
                    background-size: 25px;
                    background-position: left 10px center;

                    .message {
                        @include font-size(xs);
                        line-height: 1rem;
                    }
                }
            }
            &.with-sections {
                height: calc(100% - 140px);
                &.opened-info {
                    height: calc(100% - 195px);
                }
            }
            &.opened-info {
                height: calc(100% - 155px);
            }
        }
        .resume {
            @include display-inline-flex();
            @include align-items();
            @include justify-content(flex-end);
            float: right;
            width: 100%;
            height: 55px;
            z-index: 1;
            position: relative;
            padding-right: 0px;
            .count {
                @include font-size(sm);
                float: right;
            }
        }
        .top-container {
            padding: 0px;
        }
    }
}
</style>
