<template>
    <div class="item" :class="[itemClasses, $mq]" :data-type="type" @click="sendId(item.id)" v-if="show">
        <template v-if="['portrait'].includes($mq)">
            <div class="content-item">
                <span class="item-name">{{ item.title }}</span>
                <span class="item-info warning" v-if="item.need_justification && !item.justify_id">{{ $t('answer.justify.pending') }}</span>
                <span class="item-info warning done" v-if="item.need_justification && item.justify_id">{{ $t('answer.justify.justified') }}</span>

                <span class="item-info error" v-if="!item.need_justification && item.important && stillAvailable">{{ $t('tasks.list_important') }}</span>
                <div class="resume">
                    <span class="count">{{ item.completed + '/' + item.total }}</span>
                </div>
            </div>

            <div class="checklist-chart">
                <div class="done" :class="itemClasses" :style="{ width: item.score + '%' }"></div>
            </div>

            <div class="link"></div>
        </template>
        <template v-else>
            <div class="content-item">
                <span class="item-name">{{ item.title }}</span>

                <span class="item-info warning" v-if="item.need_justification && !item.justify_id && item.completed != item.total">{{ $t('answer.justify.pending') }}</span>
                <span class="item-info warning done" v-if="item.need_justification && item.justify_id">{{ $t('answer.justify.justified') }}</span>

                <span class="item-info error" v-if="!item.need_justification && item.important && stillAvailable">{{ $t('tasks.list_important') }}</span>
            </div>

            <div class="resume">
                <span class="count">{{ item.completed + '/' + item.total }}</span>
            </div>

            <div class="link"></div>

            <div class="checklist-chart">
                <div class="done" :class="itemClasses" :style="{ width: done == 100 ? 100 + '%' : item.score + '%' }"></div>
            </div>
        </template>
    </div>
</template>

<script>
import { FORMAT } from '@/constants'

export default {
    props: {
        item: { type: Object }
    },
    data() {
        return {
            type: 1,
            show: true
        }
    },
    computed: {
        printDate() {
            var start = moment(this.item.schedule.start)
            var end = moment(this.item.schedule.end)

            if (start.isSame(end)) {
                return this.$t('tasks.all_day')
            } else {
                return this.$tc('assets.timing.from', 2) + ' ' + start.format('HH:mm ddd') + ' ' + this.$tc('assets.timing.to', 2) + ' ' + end.format('HH:mm ddd')
            }
        },
        done() {
            // return done if item total is 0 and item completed is 0
            if (this.item && this.item.completed == 0 && this.item.total == 0) {
                return 100
            }
            return parseInt((this.item.completed / this.item.total) * 100)
        },
        stillAvailable() {
            let now = moment()
            let limit = this.item.limit
            let schedule = this.item.schedule

            if (now.isBetween(limit.start, limit.end) && !now.isBetween(schedule.start, schedule.end) && now.isAfter(schedule.end)) {
                return true
            }
            return false
        },

        itemClasses() {
            var now = moment()
            var start = moment(this.item.schedule.start)
            var end = moment(this.item.schedule.end)

            return {
                done: this.done == 100,
                progress: this.done != 100,
                pending: this.item.need_justification,
                future: start.isAfter(now),
                past: end.isSameOrBefore(now),
                intime: start.isSameOrBefore(now) && end.isSameOrAfter(now)
            }
        }
    },
    methods: {
        sendId(id) {
            this.$emit('sendId', id)
        }
    },
    created() {
        const itemsToExclude = [FORMAT.TITLE, FORMAT.SUBTITLE]
        const filteredItems = Object.values(this.item.items).filter(item => !itemsToExclude.includes(parseInt(item.type)))

        if (filteredItems.length == 0) {
            this.show = false
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
    $resume-width: 100px;
    $item-height: rem(65px);

    @include display-flex();
    @include align-items();
    @include interaction();
    @include border-radius($default-border-radius $default-border-radius 0 0);
    min-height: $item-height;
    height: auto;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    @include bgHover(#fff);

    .content-item {
        @include display-inline-flex();
        @include align-items();
        width: calc(100% - 55px - #{$resume-width});
        max-width: calc(100% - 55px - #{$resume-width});
        min-height: $item-height;
        height: auto;
        padding: 10px;
        overflow: hidden;
        float: left;

        .item-name {
            @include display-inline-flex();
            @include align-items();
            // @include text-ellipsis();
            @include font-size(ml);
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            font-family: $text-bold;
        }

        .item-time {
            @include font-size(sm);
            width: 100%;
            padding: 0;
            font-family: $text-light;
            margin: 3px 0 8px 0;
            color: #777;
            display: block;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        .item-info {
            @include border-radius($default-border-radius);
            @include font-size(s);
            font-family: $conden-bold;
            background-color: $main;
            color: #fff;
            padding: 6px;
            width: 80px;
            margin: 0 0 0 5px;
            text-align: center;
            display: inline-block;

            &.done {
                background-color: tint($main, 75%);
                color: darken($main, 25%);
            }

            &.warning {
                background-color: $warning;
                color: #fff;
                &.done {
                    background-color: tint($warning, 75%);
                    color: darken($warning, 25%);
                }
            }

            &.error {
                background-color: $error;
                color: #fff;
                &.done {
                    background-color: tint($error, 75%);
                    color: darken($error, 25%);
                }
            }
        }
    }

    .resume {
        @include display-inline-flex();
        @include align-items();
        @include justify-content();
        float: left;
        width: $resume-width;
        height: $item-height;

        .count {
            @include border-radius($default-border-radius);
            @include font-size(m);
            font-family: $text-bold;
            background-color: $default-bg-color;
            padding: 6px;
        }
    }

    .link {
        @include opacity(0.2);
        @include background($image: img('right_dark.svg'), $size: 20px);
        width: 50px;
        height: $item-height;
        display: inline-block;
        clear: both;
    }

    .checklist-chart {
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline;
        height: 4px;
        z-index: 1;
        background-color: #eee;
        width: 100%;
        overflow: auto;
        @include border-radius(0 0 2px 2px);

        .done {
            background-color: $done;
            height: 4px;
        }
        .progress {
            background-color: $progress;
            height: 4px;
        }
        .main {
            background-color: $main-t40;
        }
        .complem {
            background-color: $accent-t40;
        }
        .future {
        }
        .intime {
        }
        .past {
        }
    }

    &.pending {
        .item-name {
            width: calc(100% - 85px);
        }
    }

    @media (max-width: 900px) {
        // @include small {
        @include flex-wrap(wrap);
        padding: 12px;

        .content-item,
        .resume,
        .link {
            height: auto;
            min-height: 40px;
        }

        .content-item {
            @include display-flex();
            @include align-items(flex-start);
            width: 100%;
            max-width: 100%;
            padding: 0;
            padding-bottom: 12px;

            .item-name {
                @include font-size(m);
                word-break: break-word;
            }
            .item-info {
                @include font-size(xs);
                padding: 3px 6px;
            }
        }
        .resume {
            @include justify-content(flex-start);
            width: calc(100% - 50px);
        }
        .link {
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.item.portrait {
    margin-bottom: 10px;

    .content-item {
        display: block;
        padding: 5px !important;

        .item-name {
            @include font-size(sm);
            width: calc(100% - 40px);
            display: block;
            margin-bottom: 10px;
        }
        .item-info {
            padding: 5px 6px;
            @include font-size(xs);
        }
    }
    .resume {
        display: inline-block;
        width: auto;
        height: auto;
        min-height: auto;
        margin-top: 5px;
        .count {
            @include font-size(s);
        }
    }
    .link {
        position: absolute;
        right: 0;
        top: 29px;
    }
    .checklist-chart {
    }
}
</style>
