<template>
    <div v-if="check" ref="container" id="detail-content" class="content scrollbar right" :class="$mq">
        <div class="top-container" :class="{ infoOpened: infoIsOpen }">
            <AppccTopDetail @deleteButton="deleteIssue()" @infoOpened="infoOpened" @backButton="back()" :checklist="check" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :sections="sections"></Template-sections>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': sections.length > 0 }, { 'opened-info': infoIsOpen }]">
            <!-- <div class="top-info">
                <div class="content">
                    <span class="item-name">{{ check.title }}</span>
                    <span class="item-time">

                        <template v-if="check.complete_date">
                            <div class="hour">
                                {{ $t('assets.timing.completed') }}
                                {{ check.complete_date | moment('HH:mm') }}
                            </div>
                            <div class="date">
                                {{ check.complete_date | moment('DD/MM/YYYY') }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="hour">
                                {{ $tc('assets.timing.from', 2) }}
                                {{ getMaxHour(check.schedule.start, check.limit.start) | moment('HH:mm') }}
                                {{ $tc('assets.timing.to', 2) }}
                                {{ getMinHour(check.schedule.end, check.limit.end) | moment('HH:mm') }}
                            </div>
                            <div class="date">({{ check.schedule.start | moment('DD/MM/YYYY') }})</div>
                        </template>
                    </span>
                </div>

                <div class="resume">
                    <span class="count" :class="{ done: check.completed == check.total }">
                        <span class="completed">{{ check.completed }}</span>
                        <span class="total">{{ '/' + check.total }}</span>
                    </span>
                </div>

                <div v-if="sections.length > 0" class="sidebar-sections hide-scrollbar">
                    <div class="control-overflow">
                        <p v-for="(section, index) in sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.item_id)" v-if="section.type != 9">
                            {{ section.title }}
                        </p>
                    </div>
                </div>
            </div> -->

            <div class="exam" :class="[editable ? 'performing' : '', { 'with-sections': sections.length > 0 }]">
                <div v-if="futureChecklist" class="futureTask">
                    <div class="item">
                        <div class="answer">
                            <div class="line">
                                <div class="statement">
                                    <div class="answer-title keep-format" v-html="messageFutureTasks()"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="check.need_justification && !check.justify_id && check.completed != check.total" class="justify-description">
                    <div class="alex"></div>
                    <div class="message keep-format" v-html="getString('answer.justify.pending_desc')"></div>
                    <div class="action" @click="justify">
                        {{ getString('answer.justify.justify_it') }}
                    </div>
                </div>
                <div v-if="check.need_justification && check.justify_id" class="justify-description">
                    <div class="alex"></div>
                    <div class="message keep-format" v-html="getStringVariables('answer.justify.justified_desc', reasonDesc)"></div>
                </div>

                <div v-if="stillAvailable && parseInt(check.unanswered) != 0" class="check-available">
                    <div class="message keep-format">{{ stillAvailableFormatted }}</div>
                </div>

                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <div v-if="check.recover && editable && !check.complete_date" class="get-last-values">
                    <span class="message" @click="recoverValues()">{{ $t('tasks.get_last_values') }}</span>
                </div>

                <div v-if="check.multiple && editable" class="question complete-all">
                    <div class="item">
                        <div class="answer">
                            <div class="line">
                                <div class="statement">
                                    <div class="answer-title keep-format" v-html="$t('tasks.multiple_resolve')"></div>
                                </div>
                                <div class="response">
                                    <span class="value" :class="completeAllClasses" @click="resolveTasks()"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <template v-if="check">
                    <answer v-for="answer in check.items" :key="answer.item_id" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type"></answer>
                </template>
            </div>
        </div>
    </div>

    <div v-else class="content hide-scrollbar right empty">
        <!-- <div class="message empty">
            {{ getString('tasks.empty') }}
        </div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_tasks')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_checklists')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '../../layout/EmptyPage.vue'
import answer from '@/components/domain/tasks/answer'
import TemplateSections from '@/components/layout/TemplateSections'
import AppccTopDetail from '@/components/layout/AppccTopDetail'

export default {
    components: { answer, EmptyPage, TemplateSections, AppccTopDetail },
    props: {
        gId: { type: String },
        askTitle: { type: Boolean, default: false }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 1,
            view_more: false,
            infoIsOpen: false
        }
    },
    computed: {
        done() {
            return parseInt((this.check.completed / this.check.total) * 100)
        },
        state() {
            var now = moment()
            var start = moment(this.check.limit.start)
            var end = moment(this.check.limit.end)

            var state = false

            if (start.isAfter(now)) state = 'future'
            else if (end.isSameOrBefore(now)) state = 'past'
            else if (start.isSameOrBefore(now) && end.isSameOrAfter(now)) {
                state = 'intime'
            }

            this.check.state = state

            return state
        },
        check() {
            var check = this.$store.getters['tasks/getById'](this.gId)
            if (check) {
                // SAVE TIMEFRAMEID AND DATE OF CHECKLIST
                this.$emit('setDateTimeframe', check.schedule.start, check.timeframe_id)
            } else {
                this.$bar.hide()
            }
            return check
        },
        sections() {
            var sections = []
            for (var i in this.check.items) {
                if (this.check.def.item.itemsTitle.indexOf(this.check.items[i].type) != -1) {
                    sections.push(this.check.items[i])
                }
            }
            return sections
        },
        editable() {
            // var now = moment();
            // var start = moment(this.check.start_date);
            // var end = moment(this.check.end_date);
            //
            // return start.isSameOrBefore(now) && end.isSameOrAfter(now);
            let now = moment()
            let limit = this.check.limit

            if (now.isBetween(limit.start, limit.end) || now.isBefore(limit.start)) {
                return true
            }
            return false
        },
        leftTime() {
            return this.$root.timeLeft(this.check.schedule.end)
        },
        stillAvailable() {
            let now = moment()
            let limit = this.check.limit
            let schedule = this.check.schedule

            if (now.isBetween(limit.start, limit.end) && !now.isBetween(schedule.start, schedule.end) && now.isAfter(schedule.end)) {
                return true
            }
            return false
        },

        // VIEW RESOURCES
        stillAvailableFormatted() {
            return this.getStringVariable('answer.still_available', moment(this.check.schedule.end).format('HH'))
        },
        completeAllClasses() {
            return this.$store.getters['tasks/getCheckTasksState'](this.gId)
        },
        reasonDesc() {
            let reasons = this.$store.getters['getReasonJustify']

            return {
                reason_name: reasons ? reasons[this.check.justify_id].name : this.$t('answer.justify.default_reason'),
                reason_msg: this.check.justify_msg ? this.check.justify_msg : ''
            }
        },

        futureChecklist() {
            return moment(moment(this.check.business_date).format('YYYY-MM-DD 00:00:00')).valueOf() > moment(moment().format('YYYY-MM-DD 00:00:00')).valueOf()
        }
    },
    methods: {
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$router.push({ name: 'Checklist' })
        },

        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },

        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },

        recoverValues() {
            var self = this
            self.$overlay.loading()

            self.$store.dispatch('tasks/recoverValues', { check: self.gId }).then(function(response) {
                self.$overlay.hide()
                self.$snackbar.info({ message: self.$t('tooltip.done') })
            })
        },
        resolveTasks() {
            let self = this
            let employee = this.$store.getters['loginUser/getLocalEmployee']

            if (this.completeAllClasses == 'checked') {
                this.$store.dispatch('tasks/resetAll', { check: this.gId }).then(function(response) {
                    self.$snackbar.success()
                })
            } else {
                this.$store.dispatch('tasks/completeAll', { check: this.gId, emp: employee, date: moment().unix() }).then(function(response) {
                    self.$snackbar.success()
                })
            }
        },

        justify() {
            let thisItem = this
            let data = {
                showErrorReason: false,
                errorReason: thisItem.$t('answer.justify.error')
            }
            let list = thisItem.$store.getters['getReasonJustify']

            thisItem.$popup.justify({
                title: thisItem.$t('answer.justify.justify_it'),
                textSave: this.$t('popup.default.text_save'),
                textCancel: this.$t('popup.default.text_cancel'),
                data: data,
                list: list,
                callSave: function(self, store) {
                    if (!self.selected || self.message.trim() == '') {
                        self.data.showErrorReason = true
                    } else {
                        thisItem.$overlay.loading()

                        self.data.showErrorReason = false

                        store.dispatch('tasks/justify', { check: thisItem.gId, reason: self.selected, description: self.message }).then(function(response) {
                            thisItem.$popup.close()
                            thisItem.$overlay.hide()
                            thisItem.$snackbar.info({ message: thisItem.$t('tooltip.done') })
                        })
                    }
                }
            })
        },

        toSection(id) {
            const section = document.getElementById(id)

            switch (this.$mq) {
                case 'desktop':
                case 'landscape':
                    document.getElementById('detail-content').scrollTo({
                        top: section.offsetTop - 150,
                        behavior: 'smooth'
                    })
                    break
                case 'portrait':
                    document.getElementById('content').scrollTo({
                        top: section.offsetTop - 90,
                        behavior: 'smooth'
                    })
                    break
            }
        },
        goToReference() {
            if (this.check && this.check.data && this.check.data.ref) {
                this.$router.push({
                    name: 'ChecklistDetail',
                    params: {
                        id: this.check.data.ref.checklist,
                        checkType: 1,
                        toTaskId: this.check.data.ref.item
                    }
                })
            }
        },
        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        getMinHour(hourA, hourB) {
            return moment(hourA).valueOf() < moment(hourB).valueOf() ? hourA : hourB
        },

        getMaxHour(hourA, hourB) {
            return moment(hourA).valueOf() > moment(hourB).valueOf() ? hourA : hourB
        },

        messageFutureTasks() {
            var msg = this.$t('tasks.futureChecklist')
            var day = formatRelative(moment(this.check.schedule.start).format('YYYY-MM-DD HH:mm:ss', false))

            return msg.replace('[day]', day)
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    mounted() {},
    beforeDestroy() {
        this.$bar.hide()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.tasks.detail {
    $column-width: 20%;
    $column-max-width: rem(60px);
    $content-width: 80%;

    > .content {
        $top-info: rem(90px);
        $resume-width: rem(150px);
        overflow: hidden !important;
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;

        .detail-box {
            $sidebar-size: rem(40px);
            $collapse-button: rem(40px);
            $content-padding: rem(0px);
            $detail-bg: $default-bg-color;
            $detail-text-color: $main-dark;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            padding-bottom: $menu-aux-height;
            width: 100%;
            background-color: $detail-bg;
            height: calc(100% - 120px);
            overflow: hidden;

            &.with-sections {
                // padding-top: $top-info + $sidebar-size;
            }

            .exam {
                @include border-radius($default-border-radius);
                height: 100%;
                overflow: auto;

                &:not(.performing) {
                    // border: 2px solid #eee;
                    // background-color: #eee;
                    // filter: grayscale(70%);
                }
                &.performing {
                }

                .futureTask {
                    width: 100%;
                    margin: 30px auto;
                    position: relative;

                    .item {
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: end;
                        -ms-flex-pack: end;
                        justify-content: flex-end;
                        background-clip: padding-box;
                        height: auto;
                        height: -moz-fit-content;
                        height: -webkit-fit-content;
                        height: fit-content;
                        width: 100%;
                        padding: 0;
                        overflow: hidden;
                        margin: 0;
                        -webkit-transition-duration: 0.2s;
                        transition-duration: 0.2s;
                        height: auto;
                        min-height: 3.125rem;

                        .answer {
                            @include background($image: img('alex_carita_07.svg'), $position: left 10px center, $size: 50px);
                            width: 100%;
                            background-color: $inactive;
                            display: -webkit-inline-box;
                            display: -ms-inline-flexbox;
                            display: inline-flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -ms-flex-wrap: wrap;
                            flex-wrap: wrap;
                            width: 100%;
                            min-height: 4rem;
                            padding-right: 10px;
                            padding-left: 60px;

                            .line {
                                padding: 0 10px;

                                .statement {
                                    font-size: 1.125rem;
                                    width: 100%;
                                    // color: #222;
                                    font-family: 'Avenir-Regular';

                                    .answer-title {
                                        @include font-size(sm);
                                        color: #374673;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('file_attach_aaaaaa.svg'), $position: left 12px center, $size: 35px);
            width: 100%;
            padding: 16px 20px 16px 80px;
            margin: 0 auto 0 auto;

            .alex {
                @include background($image: img('menu_logbook_aaaaaa.svg'), $size: contain, $position: left bottom);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - 40px - 6px);
                min-height: 20px;
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            padding: 12px;
            padding-bottom: 0px;
            height: auto;
        }
        .template-sections {
            width: 100%;
            height: 50px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px;
                padding-bottom: 0px;
                max-height: 95px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 73px);
                &.with-sections {
                    height: calc(100% - 120px);

                    &.opened-info {
                        height: calc(100% - 145px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 85px);
                }
            }
        }
        &.landscape {
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 68px);
                &.with-sections {
                    height: calc(100% - 115px);

                    &.opened-info {
                        height: calc(100% - 135px);
                    }
                }
                &.opened-info {
                    height: calc(100% - 86px);
                }
            }
        }

        .justify-description {
            @include display-flex();
            @include align-items();
            @include background($color: shade($default-bg-color, 4%), $image: img('alexhead_03.svg'), $position: left 20px center, $size: 50px);
            width: 100%;
            padding: 16px 20px 16px 80px;
            margin: 0 auto 0 auto;
            $jd-button: rem(130px);
            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: calc(100% - #{$jd-button});
                min-height: 20px;
                height: auto;
                padding-right: 12px;

                > span {
                    @include keepFormat();
                    font-family: $text;
                }
            }

            .action {
                @include interaction();
                @include border-radius(3px);
                @include background($warning);
                @include font-size(sm);
                color: #fff;
                font-family: $conden-bold;
                text-align: center;
                padding: 9px;
                width: $jd-button;
                min-height: 35px;
                display: inline-block;

                @include bgHover($warning);
            }
        }

        .get-last-values {
            text-align: center;

            .message {
                @include interaction();
                @include border-radius($default-border-radius);
                @include background($color: $main-t80, $image: img('download_primary.svg'), $position: right 12px center, $size: 22px);
                @include font-size(m);
                font-family: $text;
                color: $main-dark;
                padding: 12px 42px 12px 12px;
                display: inline-block;

                @include bgHover($main-t80);
            }
        }

        .check-available {
            @include background($color: shade($default-bg-color, 4%), $image: img('countdown_primary.svg'), $position: left 26px center, $size: 30px);
            width: 100%;
            padding: 16px 20px 16px 80px;
            margin: 0 auto 0 auto;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - 40px - 6px);
                min-height: 20px;
                height: auto;
            }
        }

        .question.complete {
            .check {
                @include border-radius(100%);
                @include background($size: 16px, $color: #fff);
                border: 2px solid #eee;
            }
            .check.checked {
                // @include background($size: cover, $image: img("checked_primary.svg") );
                @include background($size: cover, $image: img('checked_ok.svg'));
                border: none;
            }
        }
    }

    > .column.left {
        $content-space: 6px;

        width: $column-width !important;
        max-width: $column-max-width !important;
        // height: calc( 100% - ( #{$alex-app-padding} * 3) ) !important;

        .timeframes {
            .tframe {
                padding: 6px 0px 6px 6px;

                .graph.chart {
                    width: 30px;
                    height: 30px;
                }
                .title.condensed {
                    @include font-size(s, $important: true);
                    @include text-ellipsis();
                }

                &.alert {
                    position: relative;

                    &:before {
                        background-image: none !important;
                        min-width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#detail-content.content.right.portrait {
    height: 100% !important;
    margin-top: 0px !important;
    padding: 0px !important;
    .detail-box {
        background: transparent;
        height: calc(100% - 58px);
        padding-bottom: 0px;

        .exam {
            height: calc(100% - 63px);
            overflow: auto;

            .justify-description,
            .check-available {
                padding: 8px 20px 8px 50px;
                background-size: 25px;
                background-position: left 10px center;

                .message {
                    @include font-size(xs);
                    line-height: 0.75rem;
                    width: calc(100% - 100px);
                }
                .action {
                    width: 100px;
                    @include font-size(xs);
                    line-height: 0.75rem;
                }
            }
            .check-available {
                .message {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .check-description {
                padding: 8px 20px 8px 50px;
                background-size: 25px;
                background-position: left 10px center;

                .message {
                    @include font-size(xs);
                    line-height: 0.75rem;
                }
            }
            .answer {
                .line {
                    .statement {
                        .answer-title {
                            @include font-size(xs, $important: true);
                        }
                    }
                }
            }
        }
        &.opened-info {
            height: calc(100% - 84px);
            padding-bottom: 0px;
        }
        &.with-sections {
            height: calc(100% - 107px);
            padding-bottom: 0px;

            &.opened-info {
                height: calc(100% - 135px);
                padding-bottom: 0px;
            }
        }
    }
    .top-container {
        height: auto;
        max-height: 115px;
        padding: 0px;
    }
}
</style>
