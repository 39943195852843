<template>
    <div id="info-container" :class="[$mq]">
        <div class="detail-info-container" :class="[{ checklist: checklist }, { full: !hasMoreOptions }]">
            <div class="btn-content">
                <div class="back-button" @click="back()"></div>
            </div>
            <div class="detail-info" :class="[{ 'with-values': showExtraDetail }, { full: !hasMoreOptions }]" @click="infoOpened()">
                <div class="detail-title" v-html="title"></div>

                <div class="detail-complete" v-if="checklist">
                    <div class="hour">
                        {{ $tc('assets.timing.from', 2) }}
                        {{ getMaxHour(checklist.schedule.start, checklist.limit.start) | moment('HH:mm') }}
                        {{ $tc('assets.timing.to', 2) }}
                        {{ getMinHour(checklist.schedule.end, checklist.limit.end) | moment('HH:mm') }}
                        {{ checklist.schedule.start | moment('DD/MM/YYYY') }}
                    </div>
                </div>
                <div class="detail-created-at" v-if="(completed && !checklist) || (showExtraDetail && checklist && completed)">
                    <span class="detail-text">
                        {{ $t('assets.timing.completed') }}
                    </span>
                    <span class="detail-text-date">
                        {{ completed | moment('DD/MM/YYYY') }}
                        {{ completed | moment('HH:mm') }}
                    </span>
                </div>
                <div class="detail-created-at" v-if="showExtraDetail && !checklist && createdAt != ''">
                    <span class="detail-text">
                        {{ $t('assets.timing.created_at') }}
                    </span>
                    <span class="detail-text-date">
                        {{ createdAt | moment('DD/MM/YYYY') }}
                        {{ createdAt | moment('HH:mm') }}
                    </span>
                </div>
                <div class="detail-user-info" v-if="showExtraDetail && userInfo">
                    <div class="employee-img" v-if="userInfo">
                        <img :src="'~@/../img/avatar/' + userInfo.avatar" alt="avatar" />
                    </div>
                    <div class="employee-name" v-if="userInfo && Object.keys(employee).length > 0">
                        {{ employee.name + ' ' + employee.surname }}
                    </div>
                </div>
                <div class="audit-points" v-if="audit">
                    <div class="status" :class="audit.status">{{ $t('audit.status.' + audit.status) }}</div>
                    <!-- <span class="top-title">{{ $t('audit.headers.score') }}:</span> -->
                    <div class="score-box" v-if="audit.total != 0">
                        <div class="score" :class="scoreClasses(audit.score)">
                            <!-- <span class="points">{{ audit.completed }}</span> -->
                            <!-- <span class="total">/{{ audit.total_score }}</span> -->
                            <span class="percent">{{ audit.score }}%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="resume" v-if="checklist">
            <span class="count" :class="{ done: checklist.completed == checklist.total }">
                <span class="completed">{{ checklist.completed }}</span>
                <span class="total">{{ '/' + checklist.total }}</span>
            </span>
        </div>
        <div class="more-actions-container" v-if="hasMoreOptions && (user.password || user.app_access)">
            <div class="more-button" :class="{ active: showExtraDetail }" @click="infoOpened()"></div>
            <div class="delete-btn" @click="removeBtn()" v-if="showExtraDetail"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppccTopDetail',
    props: {
        title: {
            type: String,
            default: ''
        },
        completed: {
            type: undefined,
            default: false
        },
        createdAt: {
            type: undefined,
            default: ''
        },
        userInfo: {
            type: undefined,
            default: false
        },
        hasMoreOptions: {
            type: Boolean,
            default: false
        },
        audit: {
            type: undefined,
            default: false
        },
        checklist: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            // Mostrar info adicional
            showExtraDetail: false
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                return employee
            }
        },
        employee() {
            return this.$store.getters['employee/getEmployee'](this.userInfo.id)
        }
    },
    methods: {
        scoreClasses(value) {
            return {
                success: value == 100,
                progress: value > 75 && value < 100,
                warning: value > 0 && value <= 75,
                error: value == 0
            }
        },
        getMaxHour(hourA, hourB) {
            return moment(hourA).valueOf() > moment(hourB).valueOf() ? hourA : hourB
        },
        getMinHour(hourA, hourB) {
            return moment(hourA).valueOf() < moment(hourB).valueOf() ? hourA : hourB
        },
        back() {
            this.$emit('backButton')
        },
        removeBtn() {
            this.$emit('deleteButton')
        },
        infoOpened() {
            if (!this.checklist) {
                this.showExtraDetail = !this.showExtraDetail
                this.$emit('infoOpened')
            } else {
                if (completed) {
                    this.showExtraDetail = !this.showExtraDetail
                    this.$emit('infoOpened')
                }
            }
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    }
}
</script>

<style lang="scss">
#info-container {
    @include display-flex();
    @include justify-content(space-between);
    width: 100%;
    height: 100%;

    .detail-info-container {
        width: calc(100% - 45px);
        @include display-flex();
        // @include align-items(center);
        @extend .interaction;

        .back-button {
            @include background($image: img('left_dark.svg'), $size: 20px);
            @include border-radius(4px);
            background-color: #fff;
            width: 40px;
            height: 40px;
        }
        .detail-info {
            @include align-self(center);
            padding-left: 12px;
            font-family: $text-bold;
            width: calc(100% - 45px);

            .detail-title {
                @extend .interaction;
                @include font-size(ml);
                @include text-ellipsis();
                font-family: $text-sbold !important;
                line-height: 30px;
            }
            .detail-complete {
                @include display-flex();
                .hour {
                    @include text-ellipsis();
                    @include font-size(sm);
                    width: calc(100% - 30px);
                    font-family: $text-light;
                    color: $main-dark;
                }
                .date {
                    padding-left: 6px;
                    @include font-size(sm);
                    width: fit-content;
                    font-family: $text-light;
                    color: $main-dark;
                }
            }
            .detail-created-at {
                width: 100%;
                @include display-flex();

                .detail-text {
                    padding-right: 6px;
                    @include font-size(s);
                }

                .detail-text-date {
                    @include text-ellipsis();
                    @include font-size(s);
                    width: max-content;
                }
            }
            .detail-text {
                @include font-size(xs);
                font-family: $text;
                color: $neutro-s60;
            }
            .detail-text-date {
                @include font-size(xs);
                font-family: $text-bold;
                color: $neutro-s60;
            }

            .detail-user-info {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                height: 22px;

                .employee-img {
                    @include display-flex();
                    @include align-items(center);
                    width: 20px;
                    height: 30px;
                }
                .employee-name {
                    @include text-ellipsis();
                    @include font-size(s);
                    padding-left: 6px;
                    font-family: $text;
                    color: $neutro-s60;
                }
            }
            .audit-points {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                max-height: 50px;
                padding-top: 6px;

                .top-title {
                    @include font-size(xs);
                    font-family: $text !important;
                    color: $neutro-s60;
                    text-align: left;
                    padding-right: 6px;
                }
                .score-box {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    @include align-items();
                    @include justify-content(flex-start);
                    @include border-radius(4px);
                    width: fit-content;
                    background-color: #fff;
                    max-width: calc(100%);
                    padding: 6px;

                    .score {
                        @include styScore();
                        width: auto;
                        text-align: right;

                        .points,
                        .total,
                        .percent {
                            display: inline-block;
                        }

                        .points {
                            @include font-size(s);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                        }
                        .total {
                            @include font-size(s);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                        }

                        .percent {
                            @include font-size(sm);
                            font-family: $text-bold;
                            margin-left: 5px;
                            color: $main-dark;
                        }
                    }
                }
                .status {
                    @include font-size(sm);
                    display: inline-block;
                    padding: 6px;
                    border-radius: 4px;
                    text-align: center;
                    line-height: 1.2;
                    margin-right: 6px;

                    &.completed {
                        background-color: $main;
                        color: white;
                    }
                    &.progress {
                        background-color: $warning;
                        color: white;
                    }
                    &.scheduled {
                        background-color: $info;
                        color: $main;
                    }
                }
            }
            &.with-values {
                align-self: unset;
            }
        }
        &.checklist {
            width: calc(100% - 80px);
            // margin-top: 10px;
            .back-button {
                margin-top: 6px;
            }
        }
        &.full {
            width: 100%;

            .detail-info {
                width: calc(100% - 25px);

                &.full {
                    align-self: center;

                    .detail-title {
                        cursor: default;
                    }
                }
            }
        }
    }

    .resume {
        @include display-flex();
        @include align-items(center);
        @include justify-content(center);
        width: 45px;
        height: 45px;

        .count {
            @include border-radius(4px);
            @include font-size(ml);
            font-family: $text-bold;
            background-color: #fff;
            padding: 6px 9px;
            color: $main-dark;

            .completed {
                color: inherit;
                font-family: inherit;
            }
            .total {
                color: inherit;
                font-family: inherit;
            }
        }
    }

    .more-actions-container {
        height: auto;
        position: relative;
        .more-button {
            @extend .interaction;
            @include background($image: img('more_vert_aaaaaa.svg'), $size: 20px);
            @include border-radius(4px);
            @include align-self(center);
            background-color: $default-sec-color;
            width: 40px;
            height: 40px;
            &.active {
                background-image: img('more_horizontal_aaaaaa.svg') !important;
            }
        }
        .delete-btn {
            @extend .interaction;
            @include background($image: img('delete_ffffff.svg'), $size: 20px);
            @include border-radius(4px);
            @include align-self(center);
            background-color: $error-dark;
            width: 40px;
            height: 40px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    &.portrait {
        .resume {
            width: 80px;
        }
        .detail-info {
            .detail-title {
                @include font-size(sm, $important: true);
            }
            .detail-created-at {
                .detail-text {
                    overflow: hidden;
                    white-space: nowrap;
                    @include font-size(xs, $important: true);
                    width: max-content;
                }

                .detail-text-date {
                    @include font-size(xs, $important: true);
                    width: auto;
                }
            }
            .employee-name {
                @include font-size(xs, $important: true);
            }
            @include portrait-tablet {
                .employee-name {
                    @include font-size(s, $important: true);
                }
                .detail-title {
                    @include font-size(m, $important: true);
                }
                .detail-created-at {
                    .detail-text {
                        @include font-size(s, $important: true);
                    }

                    .detail-text-date {
                        @include font-size(s, $important: true);
                    }
                }
            }

            &.with-values {
                .detail-title {
                    @include font-size(sm, $important: true);
                }
                .detail-created-at {
                    .detail-text {
                        overflow: hidden;
                        white-space: nowrap;
                        @include font-size(xs, $important: true);
                    }

                    .detail-text-date {
                        @include font-size(xs, $important: true);
                    }
                }
                .employee-name {
                    @include font-size(xs, $important: true);
                }
                @include portrait-tablet {
                    .employee-name {
                        @include font-size(s, $important: true);
                    }
                    .detail-title {
                        @include font-size(m, $important: true);
                    }
                    .detail-created-at {
                        .detail-text {
                            @include font-size(s, $important: true);
                        }

                        .detail-text-date {
                            @include font-size(s, $important: true);
                        }
                    }
                }
            }
        }
        .detail-info-container {
            &.full {
                width: calc(100% - 60px);

                .detail-info {
                    width: calc(100% - 40px);
                }

                .detail-complete {
                    .hour {
                        @include font-size(xs, $important: true);
                    }
                }
                @include portrait-tablet {
                    .detail-title {
                        @include font-size(m, $important: true);
                    }
                    .detail-complete {
                        .hour {
                            @include font-size(s, $important: true);
                            width: calc(100% - 2px);
                        }
                    }
                }
            }
        }
    }
}
</style>
