<template>
    <div class="detail" :class="$mq">
        <div class="top-actions">
            <div class="return-btn" @click="back()"></div>
            <div class="titl">
                <div class="name">{{ title }}</div>
            </div>

            <v-text-field v-if="!hasError && values.length > 0" class="search" v-model="search" prepend-icon="search" :placeholder="$t('vtf.datatable.search')" :label="$t('vtf.datatable.search')" hide-details clearable solo flat background-color="transparent"></v-text-field>
        </div>
        <div class="content hide-scrollbar" :class="{ 'no-scroll': values.length == 0 && !hasError }">
            <v-data-table v-if="values.length > 0" class="datatable-container" :headers="headers" :items="values" :search="search" :pagination.sync="pagination" :rows-per-page-items="[10, 25, { text: $t('vtf.datatable.all-rows'), value: -1 }]" :no-data-text="$t('vtf.datatable.no-data')" :no-results-text="$t('vtf.datatable.no-data')" :rows-per-page-text="$t('vtf.datatable.rows-per-page')" hide-actions>
                <template v-slot:headers="props">
                    <tr>
                        <!-- <th class="column col-icon" width="30px"> -->
                        <!-- <v-icon @click="filterColumns(props.item.id)">search</v-icon> -->
                        <!-- </th> -->
                        <template v-for="(header, header_index) in props.headers">
                            <th v-if="header.value == 'icon'" height="40px" class="column col-icon" width="30px" :key="header_index">
                                <button v-if="header.show_icon" class="view-column" @click="filterColumns()" type="button" name="view_column"></button>
                            </th>
                            <th v-else-if="header.value == 'date'" width="400px" height="40px" :key="header_index" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" v-show="header.visible" @click="changeSort(header.value)">
                                <v-icon small>arrow_upward</v-icon>
                                <span class="text keep-format" v-html="header.text"></span>
                            </th>
                            <th v-else :key="header_index" height="40px" :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" v-show="header.visible" @click="changeSort(header.value)">
                                <v-icon small>arrow_upward</v-icon>
                                <span class="text keep-format" v-html="header.text"></span>
                            </th>
                        </template>
                    </tr>
                </template>

                <template v-slot:items="props">
                    <tr v-on:click="sendAddedit(props.item.id)">
                        <template v-for="(column, index) in headers">
                            <template v-if="column.value == 'icon'">
                                <td v-if="column.show_icon" class="col-icon" width="30px" :key="index"></td>
                                <td v-else class="col-icon" width="30px" :key="index">
                                    <div class="edit"></div>
                                </td>
                            </template>
                            <template v-else>
                                <td :key="index" v-show="column.visible" :data-type="column.type" :data-value="props.item[column.value]" class="column" :class="{ draft: props.item.status == 0 }" @click="preview($event, props.item[column.value], column.type)" v-html="format(props.item[column.value], column.type)"></td>
                            </template>
                        </template>
                    </tr>
                </template>
                <template v-slot:no-results>{{ $t('vtf.datatable.no-result') }}</template>
            </v-data-table>
            <div class="pagination" v-if="values.length > 0 && (page > 1 || num_items * page < results)">
                <div v-if="page > 1" class="item prev" @click="page_prev()"></div>
                <div v-if="num_items * page < results" class="item next" @click="page_next()"></div>
            </div>
            <EmptyPage v-else-if="values.length == 0 && !hasError" @buttonAction="sendAddedit" :title="$t('empty_pages.record_title')" type="record" :description="$t('empty_pages.record_description')" :buttonText="$t('empty_pages.start')"></EmptyPage>
            <EmptyPage v-if="hasError" :buttonText="$t('empty_pages.return_logs')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_record')"></EmptyPage>
        </div>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage'
import { FORMAT } from '@/constants'

export default {
    components: { EmptyPage },
    props: {
        register: { type: Object },
        hasError: { type: undefined, default: false }
    },
    data() {
        return {
            search: '',
            pagination: {
                sortBy: 'dateUnix',
                rowsPerPage: -1,
                descending: true
            },
            hidden_columns: localStorage.col_register ? JSON.parse(localStorage.col_register) : [],
            FORMAT: FORMAT,
            page: 1,
            num_items: 10
        }
    },
    created() {
        const num_page = this.$store.getters['register/getNumberPage']
        if (num_page) {
            this.page = num_page
        }
    },
    computed: {
        title() {
            if (Object.keys(this.register).length > 0) {
                let keys = Object.keys(this.register)
                return keys.length > 0 ? this.register[keys[0]].title : ''
            } else {
                var template = this.$store.getters['register/getTemplateById'](this.$route.params.id)
                console.log(template)
                if (template) {
                    return template.name
                }
                return ''
            }
        },
        results() {
            return this.$store.getters['register/getNumberItems'] ? this.$store.getters['register/getNumberItems'] : false
        },
        headers: {
            get: function() {
                let schema = this.$store.getters['register/getHeaders']

                var headers = []
                // headers.push({
                //     value: 'icon',
                //     always_display: true,
                //     sortable: false,
                //     visible: true,
                //     blocked: true
                // })
                // if (!['portrait'].includes(this.$mq)) {
                //     headers.push({
                //         value: 'icon',
                //         show_icon: true,
                //         always_display: true,
                //         sortable: false,
                //         visible: true,
                //         blocked: true
                //     })
                // }
                headers.push({
                    text: this.$t('register.headers.date'),
                    value: 'date',
                    sortable: true,
                    visible: true,
                    blocked: true
                })
                headers.push({
                    text: this.$t('register.headers.employee'),
                    value: 'employee',
                    sortable: true,
                    visible: true,
                    blocked: true
                })

                if (!['portrait'].includes(this.$mq)) {
                    for (var i in schema) {
                        var raw = schema[i]
                        if (raw.type != 8 && raw.type != 9) {
                            var column = {
                                text: raw.title,
                                value: raw.id,
                                sortable: true,
                                type: raw.type,
                                // visible: true
                                visible: this.hidden_columns.indexOf(raw.id) == -1,
                                blocked: false
                            }
                            headers.push(column)
                        }
                    }
                }
                headers.push({
                    text: '',
                    value: 'arrow',
                    sortable: false,
                    visible: true
                })

                headers.push({
                    text: '',
                    value: 'status',
                    sortable: false,
                    visible: false
                })

                return headers
            },
            set: function(value) {
                this.headers = value
            }
        },
        values() {
            var values = []

            for (var i in this.register) {
                var raw = this.register[i]
                var row = {}
                // row.edit = "";
                // row.select = "";
                row.dateUnix = raw.created_date ? moment(raw.created_date).unix() : '-'
                row.date = raw.created_date ? '<span style="display: none">' + moment(raw.created_date).unix() + '</span>' + '<span class="bold">' + moment(raw.created_date).format('DD/MM/YYYY HH:mm') + '</span>' : '-'

                row.employee = raw.employee && raw.employee != null ? '<div class="img-container"> <img class="main-img" src="' + css_image_url + 'avatar/' + raw.employee.avatar + '" /><div class="name">' + raw.employee.name + '</div> </div>' : '-'
                row.id = raw.id ? raw.id : ''
                row.arrow = raw.status ? '<div class="arrow-container"> <img class="arrow-img" src="' + css_image_url + 'right_neutro.svg" />' : '<div class="arrow-container"> <div class="bullet-img"> </div> <img class="arrow-img" src="' + css_image_url + 'right_second.svg" />'
                row.status = raw.status

                for (var j in raw.items) {
                    var item = raw.items[j]
                    row.type = item.type

                    row[item.item_id] = item.value
                }

                values.push(row)
            }
            return values
        }
    },
    methods: {
        filter() {
            if (fnCheckConnection()) {
                var self = this
                var params = {
                    id: this.$route.params.id,
                    number_pages: this.page,
                    number_items: this.num_items
                }
                this.$overlay.loading()
                this.$store.dispatch('register/loadItemsPagination', params).then(function() {
                    self.$overlay.hide()
                })
            }
        },
        page_prev() {
            this.page -= 1
            this.filter()
        },
        page_next() {
            this.page += 1
            this.filter()
        },
        back() {
            this.$emit('goBack')
        },
        filterColumns() {
            var self = this
            this.$filter({
                list: this.headers,
                selected: self.hidden_columns,
                callback: function(d) {
                    self.hidden_columns = [...d]
                    self.saveFilter(self.hidden_columns)
                }
            })
            this.$filter.show()
        },

        saveFilter(filter) {
            if (localStorage) {
                localStorage.col_register = JSON.stringify(filter)
            }
        },

        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending
            } else {
                this.pagination.sortBy = column
                this.pagination.descending = false
            }
        },

        sendAddedit(id) {
            this.$emit('sendedAddedit', id)
        },

        format(value, type) {
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                case FORMAT.YESNONC:
                    return value

                case FORMAT.DATE:
                    return moment(value).format('DD/MM/YYYY')

                case FORMAT.DATETIME:
                    return value ? moment(value).format('DD/MM/YYYY HH:mm') : ''

                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    if (value && typeof value === 'object') {
                        var html = ''
                        Object.values(value).forEach(img => {
                            html += '<img src="' + img + '"/>'
                        })
                        return value ? html : value
                    } else {
                        return value ? '<img src="' + value + '"/>' : value
                    }

                default:
                    return value
            }
        },
        preview(e, value, type) {
            if (type != undefined) {
                e.preventDefault()
                e.stopPropagation()
            }
            switch (parseInt(type)) {
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    this.$popup.media({ image: value })
                    break
                default:
                    break
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.register {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;

    .img-container {
        @include justify-content();
        @include display-flex();
        width: max-content;

        .main-img {
            width: 30px;
            height: 30px;
        }
        .arrow-img {
            background-position: center right;
            width: 15px;
            height: 15px;
            margin-left: 35px;
            padding: 12px;
        }

        .name {
            @include justify-content();
            align-self: center;
            padding-left: 10px;
        }
        .bullet-img {
            @include align-self(center);
            @include border-radius(50%);
            background-color: $warning;
            width: 15px;
            height: 15px;
            padding-right: 10px;
        }
    }
    .arrow-container {
        @include justify-content(flex-end);
        @include display-flex();
        width: auto;

        .arrow-img {
            background-position: center right;
            width: 15px;
            height: 15px;
            margin-left: 15px;
        }

        .bullet-img {
            @include align-self(center);
            @include border-radius(50%);
            background-color: $warning;
            width: 15px;
            height: 15px;
            padding-right: 10px;
            position: relative;
            min-width: 15px;
            left: 6px;
        }
    }
    .bold {
        color: $neutro-s80;
        font-family: $text-bold;
    }

    > .content {
        padding-top: 0 !important;

        .detail {
            width: 100%;

            .top-actions {
                @include display-flex();
                $width-search: 300px;
                $width-title: calc(100% - #{$width-search});
                height: 50px;
                // margin: 6px 0 10px 0;
                overflow: hidden;
                padding: 12px;
                padding-bottom: 10px;
                height: 62px;

                .return-btn {
                    @extend .interaction;
                    @include background($image: img('left_dark.svg'), $size: 20px);
                    @include border-radius(4px);
                    @include align-self(center);
                    background-color: #fff;
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                }

                .titl {
                    @include align-self(center);
                    width: $width-title;
                    max-height: 80px;
                    height: auto;

                    .name {
                        @include font-size(ml);
                        margin: 0;
                        font-family: $text-bold;
                        color: $neutro-s80;
                        width: calc(100% - 50px);
                        max-height: calc(100% - 24px);
                        display: inline-block;
                        padding-left: 12px;
                        margin-top: 1px;
                    }
                    .subname {
                        @include font-size(sm);
                        width: calc(100% - 50px);
                        padding: 6px 6px 0 6px;
                        display: inline-block;
                        color: #999;
                    }
                }

                .search {
                    float: right;
                    width: $width-search;

                    .v-input__slot {
                        padding-left: 0;

                        .v-text-field__slot input {
                            @include border-radius(0);
                            min-height: 35px;
                            padding: 6px;
                            background: transparent;
                        }
                    }
                }
            }
            .datatable-container {
                padding: 6px 0px 6px 12px;

                .theme--light.v-table {
                    background-color: transparent !important;
                }
            }
            .pagination {
                width: 100%;
                padding: 0px;
                display: block;
                grid-column: 1 / 4;

                .item {
                    width: calc(50% - 10px);
                    height: 45px;
                    margin: 0 5px;
                    float: left;
                    @include border-radius(4px);
                    @extend .interaction;

                    &.next {
                        @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                        float: right;
                    }
                    &.prev {
                        @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                    }
                }
            }
        }

        .v-table {
            thead {
                height: 40px;
                .v-datatable__progress {
                    display: none;
                }
                tr {
                    height: 40px;
                }

                .col-icon {
                    padding: 15px 12px;
                }
                .column {
                    background-color: $default-sec-color;
                    height: 40px !important;
                    padding: 0px;
                }
                .column,
                .column > .text {
                    @include font-size(sm);
                    font-family: $text;
                    color: $neutro-s80;
                    text-align: left;
                    text-transform: uppercase;
                }
                .column > .text {
                    @include text-ellipsis();
                }

                .col-icon {
                    padding: 0 !important;

                    .view-column {
                        @include background($image: img('view_column_777777.svg'), $size: 20px);
                        width: 40px;
                        height: 40px;
                        margin: 5px 0;
                        padding: 0;
                    }
                }
            }

            tbody {
                tr {
                    border: none;
                }
                .col-icon {
                    padding: 15px 12px;
                    padding: 15px 12px !important;
                }
                .edit {
                    @include background($image: img('edit_neutro.svg'), $position: center, $size: 20px);
                    background-color: $neutro;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    @include border-radius(4px);
                }
                .column {
                    text-align: left;
                    font-family: $text-light;

                    &[data-type='1'],
                    &[data-type='2'],
                    &[data-type='3'] {
                        @include background($size: 24px);
                        color: transparent;
                    }

                    &[data-type='1'][data-value='0'] {
                        background-image: img('error_aaaaaa.svg');
                    }
                    &[data-type='1'][data-value='1'] {
                        background-image: img('checked_ok.svg');
                        background-position: 20px;
                    }

                    &[data-type='2'][data-value='0'],
                    &[data-type='3'][data-value='0'] {
                        background-image: img('error_alert.svg');
                    }
                    &[data-type='2'][data-value='1'],
                    &[data-type='3'][data-value='1'] {
                        background-image: img('checked_ok.svg');
                    }
                    &[data-type='3'][data-value='2'] {
                        background-image: img('question_aaaaaa.svg');
                    }

                    img {
                        height: 60px;
                    }
                    .name {
                        font-family: $text-light;
                    }

                    &.draft {
                        background-color: $warning-t70;
                        padding: 12px;
                    }
                }
            }

            .column {
                @include text-ellipsis();
                max-width: 300px;
                padding: 12px 9px;
            }
        }
    }
    .detail.desktop {
        .arrow-img {
            margin-left: 35px;
        }
    }
    .detail.landscape {
        .top-actions {
            padding-bottom: 10px;
            height: 61px;
            padding-left: 11px;
            padding-top: 10px;

            .name {
                margin-top: 3px !important;
            }
        }
        .arrow-img {
            margin-left: 35px;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
#content.register {
    > .content {
        // overflow: hidden !important;

        .detail {
            .content {
                overflow: auto;
                height: calc(100vh - 200px);

                &.no-scroll {
                    overflow: hidden !important;
                }
            }
        }

        .detail.portrait {
            .top-actions {
                width: 100%;
                padding: 0;
                .titl {
                    @include display-flex();
                    width: 100%;
                    padding: 0;

                    @include portrait-tablet {
                        .name {
                            @include font-size(m, $important: true);
                        }
                    }
                    .name {
                        @include font-size(sm);
                        @include text-ellipsis();
                        font-family: $text-bold;
                        width: calc(100% - 26px);
                        padding: 0;
                        padding-left: 12px;
                    }
                }
                .search {
                    width: 100%;
                    display: none;
                }
            }

            .content {
                overflow: auto;
                height: calc(100vh - 200px);
                padding: 0px !important;

                .v-table {
                    tbody {
                        td {
                            padding: 10px 10px;
                            height: 80px;
                            @include font-size(xs);

                            &.col-icon {
                                padding-left: 20px;
                            }
                        }
                    }
                    th {
                        text-align: left !important;

                        .text {
                            @include font-size(xs);
                            text-transform: uppercase;
                            font-family: $text;
                            color: $neutro-s80;
                        }
                    }
                }

                .datatable-container {
                    padding: 0px;
                    padding-bottom: 12px;
                }
                .pagination {
                    width: 100%;
                    padding: 0px;
                    display: block;
                    grid-column: 1 / 4;
                    height: 45px;
                    // margin-top: 75px;
                    position: relative;
                    // top: -75px;
                    margin-bottom: 10px;

                    .item {
                        width: calc(50% - 5px);
                        height: 45px;
                        margin: 0 0px;
                        float: left;
                        @include border-radius(4px);
                        @extend .interaction;

                        &.next {
                            @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                            float: right;
                            @extend .interaction;
                        }
                        &.prev {
                            @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $default-sec-color);
                        }
                    }
                }
            }
        }
    }
}
</style>
