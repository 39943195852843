<template>
    <div v-if="check" ref="container" class="audit-content scrollbar right" :class="[$mq]">
        <div class="top-container" :class="{ 'opened-info': infoIsOpen }">
            <AppccTopDetail :hasMoreOptions="true" @infoOpened="infoOpened" @deleteButton="deleteAudit()" @backButton="back()" :audit="check" :userInfo="check.employee" :title="check.title" :createdAt="check.created_date" :completed="check.complete_date"></AppccTopDetail>
        </div>
        <div class="template-sections" v-if="sections.length > 1">
            <Template-sections :isAudit="true" :sections="sections"></Template-sections>
        </div>
        <div class="detail-box" :class="[{ 'with-sections': Object.keys(check.sections).length > 0 }, { 'sidebar-collapsed': collapsedInfo }, { 'opened-info': infoIsOpen }]">
            <!-- <div class="top-info">
                <div class="content">
                    <span class="item-name">{{ check.title }}</span>
                    <span class="item-data">
                        <template v-if="check.complete_date">
                            <div class="date">{{ check.complete_date | moment('DD [' + this.$t('calendar.separator') + '] MMMM [' + this.$t('calendar.separator') + '] YYYY') }}</div>
                        </template>
                        <template v-if="check.employee">
                            <div class="employee">
                                <UserTag :userId="check.employee.id" size="medium" styleOf="block" :hasAvatar="true"></UserTag>
                            </div>
                        </template>
                        <template>
                            <div class="status" :class="check.status">{{ $t('audit.status.' + check.status) }}</div>
                        </template>
                    </span>
                </div>

                <div class="resume">
                    <div class="score-box">
                        <span class="top-title">{{ $t('audit.headers.score') }}</span>
                        <div class="score" :class="scoreClasses(check.score)">
                            <span class="points">{{ check.completed }}</span>
                            <span class="total">/{{ check.total_score }}</span>
                            <span class="percent">({{ check.score }}%)</span>
                        </div>
                    </div>
                    <span class="toggle-info" :class="{ opened: collapsedInfo }" @click="toggleInfo"></span>
                </div>

            </div> -->
            <!-- <div v-if="Object.keys(check.sections).length > 0" class="sidebar-sections hide-scrollbar">
                <div class="control-overflow">
                    <p v-for="(section, index) in check.sections" class="item" :class="{ subtitle: section.type == 9 }" :key="index" @click="toSection(section.section_id)" v-if="section.type != 9">
                        <span class="name">{{ section.name }}</span>
                        <span class="score">{{ section.score }}%</span>
                    </p>
                </div>
            </div> -->

            <div v-if="collapsedInfo" class="list-info">
                <span class="action delete" @click="deleteAudit()">{{ $t('audit.actions.delete') }}</span>
            </div>

            <div class="exam performing">
                <div class="section minimize">
                    <span v-if="closedSections.length != Object.keys(check.sections).length" class="section-title" @click.self="toggleAllSections('collapse')">{{ $t('audit.actions.collapse_all') }}</span>
                    <span v-else class="section-title expand" @click.self="toggleAllSections('expand')">{{ $t('audit.actions.expand_all') }}</span>
                </div>
                <div v-if="check.description" class="check-description">
                    <div class="message keep-format" v-html="descriptionParse(check.description)" @click="view_more = !view_more"></div>
                </div>

                <template v-if="check">
                    <div v-if="check.sections" v-for="section in check.sections" class="section" :class="{ closed: closedSections.includes(section.section_id) }" :id="section.section_id">
                        <span class="section-title" @click.self="toggleSection(section.section_id)">{{ section.name }}</span>
                        <span class="section-score" :class="scoreClasses(section.score)">
                            <span class="points">{{ section.completed }}</span>
                            <span class="total">/{{ section.total_score }}</span>
                        </span>
                        <div class="section-content">
                            <template v-if="!closedSections.includes(section.section_id)">
                                <answer v-for="answer in section.items" :item="answer" :list="check" :cId="check.id" :aId="answer.item_id" :cType="type" :key="answer.item_id"></answer>
                            </template>

                            <!--  <div class="section-total">
                <span class="text">{{ $t('audit.subtotal') }}</span>
                <span class="total" :class="[{'success': section.completed > 0},{'error': section.completed <= 0}]">
                  {{section.completed ? section.completed >= 0 ? "+" : "-" : ""}} {{section.completed}}
                </span>
              </div>-->
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div v-else class="audit-content hide-scrollbar right empty">
        <!-- <div class="message empty">{{ getString('audit.empty') }}</div> -->
        <EmptyPage :buttonText="$t('empty_pages.return_audits')" @buttonAction="back()" buttonClass="back" :type="'error'" :title="$t('assets.overlay.timeout.message')" :description="$t('empty_pages.error_audit')"></EmptyPage>
    </div>
</template>

<script>
import EmptyPage from '@/components/layout/EmptyPage.vue'
import answer from '@/components/domain/tasks/answer'
import UserTag from '@/components/layout/UserTag'
import AppccTopDetail from '@/components/layout/AppccTopDetail'
import TemplateSections from '@/components/layout/TemplateSections'
export default {
    components: { answer, UserTag, EmptyPage, AppccTopDetail, TemplateSections },
    props: {
        gId: { type: String }
    },
    data() {
        return {
            collapsedInfo: false,
            type: 2,
            firstTime: true,
            closedSections: [],
            view_more: false,
            infoIsOpen: false
        }
    },
    computed: {
        check() {
            var check = this.$store.getters['audit/getById'](this.gId)
            if (check) {
                // check.employee.avatar = css_image_url + 'avatar/' + check.employee.avatar
                if (this.closedSections.length == 0 && this.firstTime) {
                    this.firstTime = false
                    var self = this
                    var n = 0
                    for (var k in check.sections) {
                        var section = { ...check.sections[k] }
                        if (n > 0) {
                            self.closedSections.push(section.section_id)
                        }
                        n++
                    }
                }
            }
            if (check == undefined) {
                this.$bar.hide()
            }
            return check
        },
        user() {
            var userID = this.$store.getters['loginUser/getLocalEmployee']
            if (userID) {
                return this.$store.getters['employee/getEmployee'](userID)
            }
        },
        sections() {
            var sections = []
            if (this.check) {
                for (var i in this.check.sections) {
                    if (this.check.sections[i].name) {
                        sections.push(this.check.sections[i])
                    }
                }
            }
            return sections
        }
    },
    methods: {
        infoOpened() {
            this.infoIsOpen = !this.infoIsOpen
        },
        back() {
            this.$router.push({
                name: 'AuditDetail',
                params: { id: this.$route.params.id }
            })
        },
        descriptionParse(msg) {
            if (msg.split(' ').length < 100) {
                return msgParse(msg)
            } else {
                if (this.view_more) {
                    return msgParse(msg) + ' <span class="more"> ' + this.$t('label.less') + '</span>'
                } else {
                    var maxLength = 150 // maximum number of characters to extract

                    var trimmedMsg = msg.substr(0, maxLength)

                    trimmedMsg = trimmedMsg.substr(0, Math.min(trimmedMsg.length, trimmedMsg.lastIndexOf(' ')))
                    return msgParse(trimmedMsg) + '... <span class="more">' + this.$t('label.more') + '</span>'
                }
            }
        },
        getString(str) {
            return this.$t(str)
        },
        getStringVariables(str, params) {
            return this.$t(str, params)
        },
        getStringPlural(str, pos) {
            return this.$tc(str, pos)
        },
        getStringVariable(str, pos) {
            return this.$t(str, { s: pos })
        },
        getStringPluralVariable(str, pos, text) {
            return this.$tc(str, pos, { s: text })
        },

        scoreClasses(value) {
            return {
                success: value == 100,
                progress: value > 75 && value < 100,
                warning: value > 0 && value <= 75,
                error: value == 0
            }
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        toggleSection(sec_id) {
            if (this.closedSections.includes(sec_id)) {
                var closedSections = [...this.closedSections]
                closedSections.splice(closedSections.indexOf(sec_id), 1)
                this.closedSections = [...closedSections]
            } else {
                this.closedSections.push(sec_id)
            }
        },

        toggleInfo() {
            this.collapsedInfo = !this.collapsedInfo
        },

        toggleAllSections(mode) {
            if (mode == 'collapse') {
                if (this.closedSections.length != Object.keys(this.check.sections).length) {
                    this.closedSections = []
                    for (var i in Object.keys(this.check.sections)) {
                        var section = Object.keys(this.check.sections)[i]
                        this.closedSections.push(section)
                    }
                }
            } else {
                this.closedSections = []
            }
        },

        deleteAudit() {
            var self = this
            var employee = self.currentEmployee()

            if (!self.$store.getters['hasAccess'](employee.id, 1)) {
                self.$popup.access({
                    list: self.$store.getters['employee/getLoginUsers'](1),
                    callAfterAccess: function(response) {
                        if (response.status) {
                            self.$popup.close()
                            self.popupDeleteAudit(response.emp_id)
                        }
                    }
                })
            } else {
                self.popupDeleteAudit(employee.id)
            }
        },
        popupDeleteAudit(emp_id) {
            var self = this
            var relItem = self.refTask ? self.refTask.task_id : false
            // Validate user rol is in template audit
            var template = self.$store.getters['audit/getTemplateById'](self.$route.params.id)
            var rolEmployee = self.$store.getters['employee/getRol'](this.user.rol)
            var auditTemplateHasRoleUser = false

            var templateRoles = template.config.roles
            if (typeof templateRoles === 'undefined' || templateRoles.length == 0 || templateRoles.includes(rolEmployee.id)) {
                auditTemplateHasRoleUser = true
            }

            if (auditTemplateHasRoleUser) {
                self.$popup.confirm({
                    message: self.$t('popup.delete.title'),
                    textSave: self.$t('popup.delete.text_save'),
                    textCancel: self.$t('popup.delete.text_cancel'),
                    callSave: function() {
                        self.$overlay.loading()
                        self.$store
                            .dispatch('audit/delete', {
                                check: self.gId,
                                emp: emp_id,
                                ref_item: relItem,
                                template: self.check.template_id
                            })
                            .then(function(response) {
                                if (response) {
                                    self.$popup.close()
                                    self.$overlay.hide()
                                    self.$snackbar.success({ duration: 5000, closeable: true, message: self.$t('popup.audits.removed_successfully') })

                                    self.$router.push({
                                        name: 'AuditDetail',
                                        params: { id: self.check.template_id }
                                    })
                                }
                            })
                    }
                })
            } else {
                self.$popup.alert({
                    closable: false,
                    blocking: false,
                    message: self.$t('popup.audits.role_not_allowed'),
                    textSave: self.$t('popup.audits.exit'),
                    callSave: function(response) {
                        self.$popup.close()
                    }
                })
            }
        },

        toSection(id) {
            var scroll = {
                container: this.$refs.container,
                target: document.getElementById(id),
                offset: this.check.sections.length > 0 ? -165 : -105
            }

            this.$root.scrollTo(scroll)
        },

        redirectPath(firstResult) {
            return this.$root.redirectPath(firstResult)
        },

        employee(id) {
            return this.$store.getters['employee/getEmployee'](id)
        }
    },
    filters: {
        moment: function(date, format) {
            return date ? moment(date).format(format) : ''
        }
    },
    mounted() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.audit.detail {
    $column-width: 0%;
    $column-max-width: 0px;
    $content-width: 100%;
    height: min-content;

    > .audit-content {
        $top-info: 80px;
        $resume-width: rem(230px);
        padding-top: 0;
        padding-bottom: 0 !important;
        width: calc(#{$content-width}) !important;
        min-width: calc(100% - #{$column-max-width}) !important;
        height: 100%;
        overflow: hidden;

        .detail-box {
            $sidebar-size: 40px;
            $collapse-button: 40px;
            $content-padding: 0px;
            $detail-bg: $default-bg-color;
            $detail-text-color: $main-dark;

            position: relative;
            padding: 0;
            padding-left: $content-padding;
            width: 100%;
            height: calc(100% - 120px);
            overflow: auto;
            background-color: $detail-bg;

            &.with-sections {
            }

            .list-info {
                $action-size: rem(60px);
                @include default-box-shadow();
                @include display-flex();
                @include justify-content(flex-end);
                @include align-items();
                @include flex-direction(row);
                @include flex-wrap(nowrap);
                background-color: shade($default-bg-color, 5%);
                position: fixed;
                z-index: 9;
                width: calc(100% - #{$column-max-width} - #{$menu-tools-min} - #{$content-padding} - #{$scroll-width});
                height: $action-size;

                .action {
                    @include interaction();
                    @include font-size(xs);
                    font-family: $text-bold;
                    color: #444;
                    text-align: center;
                    display: block;
                    background-color: shade($default-bg-color, 7%);
                    width: $action-size;
                    height: $action-size;
                    padding: ($action-size - rem(20px)) 6px 2px 6px;
                    margin: 0 9px;

                    @include bgHover(shade($default-bg-color, 7%));

                    &.delete {
                        @include background($image: img('delete_alert.svg'), $size: 24px, $position: top 9px center);
                    }
                }
            }

            .exam {
                @include border-radius($default-border-radius);
                padding: 12px 0;
                height: 100%;

                .minimize {
                    opacity: 0.85;
                    margin-bottom: 0px;
                    margin-top: 0px;

                    &:hover {
                        opacity: 1;
                    }

                    .section-title {
                        @include font-size(s);

                        &.expand:before {
                            background-image: img('more_primary.svg');
                        }
                    }
                }

                .expand:before {
                    background-image: img('more_primary.svg');
                }

                .section {
                    $section-score-width: 120px;

                    padding: 6px;
                    width: 94%;
                    margin: 12px auto;

                    .section-title {
                        @include font-size(ml);
                        font-family: $text;
                        color: $main-dark;
                        display: inline-block;
                        width: calc(100% - #{$section-score-width});
                        margin-bottom: 4px;

                        &:before {
                            @include background($color: $main-t80, $image: img('less_primary.svg'), $size: 10px);
                            @include border-radius(3px);
                            content: '';
                            display: inline-block;
                            width: 18px;
                            height: 18px;
                            margin-right: 6px;
                            margin-bottom: -2px;
                        }
                    }
                    .section-score {
                        display: inline-block;
                        width: $section-score-width;
                        text-align: right;

                        .points {
                            @include font-size(l);
                            font-family: $conden-bold;
                            color: $main;
                        }
                        .total {
                            @include font-size(sm);
                            font-family: $conden-bold;
                            color: $neutro-s90;
                        }
                    }

                    .section-content {
                        @include display-flex();
                        @include flex-direction(row);
                        @include flex-wrap(wrap);
                        @include border-radius($default-border-radius);

                        .question-score {
                            @include display-inline-flex();
                            // @include justify-content();
                            @include align-items();
                            @include font-size(m);
                            margin: 20px auto;
                            font-family: $text;
                            color: $main;
                            width: 80px;
                            height: 50px;
                        }
                    }

                    .section-total {
                        width: 100%;
                        text-align: right;

                        .text {
                            @include font-size(sm);
                            font-family: $text-bold;
                            color: $main-dark;
                        }
                        .total {
                            @include styScore();
                            @include font-size(ml);
                            font-family: $text-bold;
                            color: $main;
                            text-align: center;
                            display: inline-block;
                            width: 80px;
                            padding: 6px;
                        }
                    }

                    &.closed {
                        height: 50px;
                        margin: 12px auto;

                        .section-title:before {
                            background-image: img('more_primary.svg');
                        }

                        .section-content {
                            visibility: hidden;
                            display: none;
                            height: 0;
                            opacity: 0;

                            // .question, .question-score {
                            //   display: none;
                            // }
                        }
                    }
                }
            }
        }

        .check-description {
            @include background($color: shade($default-bg-color, 4%), $image: img('alexhead_06.svg'), $position: left 12px center, $size: 50px);
            width: 100%;
            padding: 16px 20px 16px 80px;
            margin: 0 auto 0 auto;

            .alex {
                @include background($image: img('alexhead_04.svg'), $size: contain, $position: left bottom);
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 6px;
            }

            .message {
                @include font-size(sm);
                // line-height: 20px;
                font-family: $text;
                color: $main-dark;
                display: inline-block;
                overflow: visible;
                width: auto;
                max-width: calc(100% - 40px - 6px);
                min-height: 20px;
                height: auto;

                .more {
                    color: $main-t20;
                    cursor: pointer;
                }
            }
        }
        .top-container {
            background-color: $default-bg-color;
            width: 100%;
            padding-top: 11px;
            height: auto;
            padding-bottom: 10px;
        }
        &.desktop {
            .top-container {
                background-color: $default-bg-color;
                width: 100%;
                padding: 12px;
                padding-bottom: 10px;
                max-height: 150px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 160px);
                &.with-sections {
                    height: calc(100% - 220px);

                    &.opened-info {
                        height: calc(100% - 255px);
                    }
                }

                &.opened-info {
                    height: calc(100% - 195px);
                }
            }
        }
        &.landscape {
            .top-container {
                padding-left: 11px;
                padding-right: 11px;
            }
            // Calcular la altura de todo el contenido top que es fijo para el listado
            .detail-box {
                height: calc(100% - 140px);

                &.with-sections {
                    height: calc(100% - 200px);

                    &.opened-info {
                        height: calc(100% - 245px);
                    }
                }

                &.opened-info {
                    height: calc(100% - 175px);
                }
            }
        }
    }

    > .column.left {
        $content-space: 6px;
        width: $column-width !important;
        max-width: $column-max-width !important;
    }

    // TODO REVIEW
    .question.child {
        // margin: rem(15px) auto;

        &:nth-of-type(2) {
            //   width: 85% !important;
            //   margin-left: 9%;
        }
    }

    .question {
        margin: rem(15px) auto;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#content.audit {
    &.detail {
        .audit-content.portrait {
            padding: 10px;
            height: 100%;

            .detail-box {
                // padding-top: 60px !important;
                background: transparent;
                height: calc(100% - 175px);

                &.opened-info {
                    height: calc(100% - 220px);
                }

                .message {
                    padding-left: 10px;
                }

                .exam {
                    .section {
                        width: 100%;
                    }
                    .justify-description,
                    .check-available {
                        padding: 8px 20px 8px 50px;
                        background-size: 25px;
                        background-position: left 10px center;

                        .message {
                            @include font-size(xs);
                            line-height: 1rem;
                            width: calc(100% - 100px);
                        }
                        .action {
                            width: 100px;
                            @include font-size(xs);
                            line-height: 0.75rem;
                        }
                    }
                    .check-available {
                        .message {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                    .check-description {
                        padding: 8px 20px 8px 50px;
                        background-size: 25px;
                        background-position: left 10px center;

                        .message {
                            @include font-size(xs);
                            line-height: 1rem;
                        }
                    }
                }
                &.not-saved {
                    height: calc(100% - 115px);
                    padding-bottom: 0px;
                }
                &.opened-info {
                    height: calc(100% - 115px);
                    padding-bottom: 0px;
                }
                &.with-sections {
                    height: calc(100% - 205px);
                    padding-bottom: 0px;

                    &.opened-info {
                        height: calc(100% - 255px);
                    }
                }
            }
            .top-container {
                height: auto;
                max-height: 120px;
                padding-top: 2px;

                &.opened-info {
                    max-height: 170px;
                }
            }
        }
    }
}
</style>
